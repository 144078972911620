import { Box, Typography } from '@mui/material';
import assets from '../../../../assets';
import styles from './styles.module.scss';

type Props = {
  title: string;
  errorMessage: string;
  children: React.ReactNode;
};
const AuthenticationBox = ({ title, errorMessage, children }: Props) => {
  return (
    <Box className={styles.paperBox}>
      <Box className={styles.mainDiv}>
        <Box
          className={styles.logoBox}
          component="img"
          alt="logo"
          src={assets.icons.logo}
        />
        <Typography className={styles.textTitle}>{title}</Typography>
        <Typography className={styles.error}>{errorMessage}</Typography>
        <Box width={'100%'}>{children}</Box>
      </Box>
    </Box>
  );
};

export default AuthenticationBox;
