import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import style from './styles.module.scss';
import useDarkMode from 'hooks/useDarkMode';
import { useEffect, useState } from 'react';
import { auth, firestore } from 'configs/firebaseConfig';
import { getUidByEmail } from 'api/get-uid';
import { doc, updateDoc } from 'firebase/firestore';
import { getCssVariable } from 'styles/getVariables';

const updateUserThemePreference = async (value: number) => {
  if (auth.currentUser?.email != null) {
    const uid = await getUidByEmail('Users', auth.currentUser?.email);
    if (uid) {
      const updateDocRef = doc(firestore, 'Users', uid);
      await updateDoc(updateDocRef, {
        theme: value,
      });
    }
  }
};

const UserPreferences = () => {
  const { setUserThemePreference } = useDarkMode();
  const [userTheme, setUserTheme] = useState(0);

  const handleThemeChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedTheme = parseInt(event.target.value) as 0 | 1 | 2;

    await updateUserThemePreference(selectedTheme);

    setUserThemePreference(selectedTheme);
    setUserTheme(selectedTheme);
    window.location.reload();
  };

  useEffect(() => {
    const t = parseInt(localStorage.getItem('userPreferedTheme') || '0');
    setUserTheme(t);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.mainDiv}>
      <h2 className={style.settingsTitle}>Ustawienia</h2>
      <Grid container spacing={2} width={'100%'} padding={3}>
        <FormControl>
          <FormLabel
            sx={{
              color: getCssVariable('--text-primary'),
            }}
          >
            Motyw kolorystyczny
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={userTheme.toString()}
            name="radio-buttons-group"
            onChange={handleThemeChange}
          >
            <FormControlLabel
              value="2"
              control={
                <Radio
                  sx={{
                    color: getCssVariable('--text-primary'),
                    '&.Mui-checked': {
                      color: getCssVariable('--text-primary'),
                    },
                  }}
                />
              }
              label="Motyw jasny"
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: getCssVariable('--text-primary'),
                },
              }}
            />
            <FormControlLabel
              value="1"
              control={
                <Radio
                  sx={{
                    color: getCssVariable('--text-primary'),
                    '&.Mui-checked': {
                      color: getCssVariable('--text-primary'),
                    },
                  }}
                />
              }
              label="Motyw ciemny"
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: getCssVariable('--text-primary'),
                },
              }}
            />
            <FormControlLabel
              value="0"
              control={
                <Radio
                  sx={{
                    color: getCssVariable('--text-primary'),
                    '&.Mui-checked': {
                      color: getCssVariable('--text-primary'),
                    },
                  }}
                />
              }
              label="Motyw systemu (wybierany w ustawieniach komputera / telefonu)"
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: getCssVariable('--text-primary'),
                },
              }}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </div>
  );
};

export default UserPreferences;
