import { Box, BoxProps, CircularProgress } from '@mui/material';
import loadingGif from '../../assets/gif/wedges-big.gif';
import colors from 'configs/colorConfig';

type Props = BoxProps & {
  isLoading: boolean;
  children: React.ReactNode;
};

const LoadingWrapper = ({ isLoading, children, ...boxProps }: Props) => {
  return (
    <>
      {isLoading ? (
        <Box
          {...boxProps}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {/* <img
            width={'60px'}
            height={'60px'}
            src={loadingGif}
            alt="loading..."
          /> */}
          <Box>
            <CircularProgress size={60} style={{ color: colors.primary }} />
          </Box>
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export default LoadingWrapper;
