import { collection, getDocs, query } from 'firebase/firestore';
import { firestore } from '../../../configs/firebaseConfig';
import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

const UserList = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<string[]>([]);

  const getUsers = async () => {
    setLoading(true);
    const querySnapshot = await getDocs(query(collection(firestore, 'Users')));

    const usersList: { email: string; createdAt: Date }[] = querySnapshot.docs
      .map((doc) => {
        return {
          email: doc.data().email,
          createdAt: doc.data().creationDate?.seconds ?? 0,
        };
      })
      .sort((a, b) => b.createdAt - a.createdAt);
    setUsers(usersList.map((user) => user.email));
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Box
      width={'400px'}
      height={'500px'}
      padding="30px"
      style={{
        overflowY: 'auto',
        border: '1px solid lightGray',
        borderRadius: '5px',
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <div>
          {users.map((user, index) => {
            return (
              <div key={index} style={{ marginBottom: '7px' }}>
                {user}
              </div>
            );
          })}
        </div>
      )}
    </Box>
  );
};

export default UserList;
