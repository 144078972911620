import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import CodeSnippet from '../code-snippet/CodeSnippet';
import useTextHighlight from '../../../../../../hooks/useTextHighlight';
import { getCssVariable } from 'styles/getVariables';

type Props = {
  responseText: string;
};

interface BotResponseChunks {
  text: string;
  typw: 'text' | 'code';
}

const textColor = getCssVariable('--text-primary');

const parseGPT4oMarkdown = (text: string) => {
  let parsedText = text;

  parsedText = parsedText.replaceAll(
    /### (.*$)/gim,
    '<h2 style="font-size:14px; border:0px; text-transform:none">$1</h2>'
  );

  parsedText = parsedText.replaceAll(
    /## (.*$)/gim,
    '<h2 style="font-size:14px; border:0px; text-transform:none">$1</h2>'
  );

  parsedText = parsedText.replaceAll(/^# (.*$)/gim, '<h1>$1</h1>');

  parsedText = parsedText.replaceAll(/^\* (.*$)/gim, '<li>$1</li>');

  parsedText = parsedText.replaceAll(
    /^> (.*$)/gim,
    '<blockquote>$1</blockquote>'
  );

  parsedText = parsedText.replaceAll(/^=> (.*$)/gim, '<a href="$1">$1</a>');

  parsedText = parsedText.replaceAll(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

  parsedText = parsedText.replaceAll(/\*(.*?)\*/g, '<em>$1</em>');

  parsedText = parsedText.replaceAll(/```(.*?)```/g, '<code>$1</code>');

  parsedText = parsedText.replaceAll(/\n/g, '<br/>');
  parsedText = parsedText.replaceAll(/\r/g, '<br/>');
  parsedText = parsedText.replaceAll('<br/><br/>', '<br/>');
  parsedText = parsedText.replaceAll('<br/><br/>', '<br/>');
  parsedText = parsedText.replaceAll('<br/><br/>', '<br/>');
  parsedText = parsedText.replaceAll('<br/><br/>', '<br/>');

  parsedText = parsedText.replace(/(<\/h[12]>)<br\/>/g, '$1');
  return parsedText;
};

// const parseGeminiMarkdown = (text: string) => {
//   let parsedText = text;

//   parsedText = parsedText.replace(/^# (.*$)/gim, "<h1>$1</h1>");

//   parsedText = parsedText.replace(
//     /## (.*$)/gim,
//     '<h2 style="font-size:14px; border:0px; text-transform:none">$1</h2>'
//   );

//   parsedText = parsedText.replace(/^\* (.*$)/gim, "<li>$1</li>");

//   parsedText = parsedText.replace(/^> (.*$)/gim, "<blockquote>$1</blockquote>");

//   parsedText = parsedText.replace(/^=> (.*$)/gim, '<a href="$1">$1</a>');

//   parsedText = parsedText.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

//   parsedText = parsedText.replace(/\*(.*?)\*/g, "<em>$1</em>");

//   parsedText = parsedText.replace(/```(.*?)```/g, "<code>$1</code>");

//   parsedText = parsedText.replace(/\n/g, "<br>");

//   return parsedText;
// };

function closeOpenTags(html: string): string {
  const listOfTags = [
    'h1',
    'h2',
    'li',
    'blockquote',
    'a',
    'strong',
    'em',
    'code',
  ];

  listOfTags.forEach((tag) => {
    const countOne = (html.match(new RegExp(`<${tag}`, 'g')) || []).length;
    const countTwo = (html.match(new RegExp(`</${tag}`, 'g')) || []).length;
    if (countOne > countTwo) {
      html += `</${tag}>`;
    }
  });

  return html;
}

const BotResponsre = ({ responseText }: Props) => {
  const { blockedText } = useTextHighlight();

  const [responseChunks, setResponseChunks] = useState<BotResponseChunks[]>([]);

  useEffect(() => {
    // const chunks = responseText.split("```");
    // const responseChunks: BotResponseChunks[] = [];

    // let html = parseGeminiMarkdown(responseText);
    let html = parseGPT4oMarkdown(responseText);
    html = closeOpenTags(html);
    html = html.replaceAll('<br>', '');

    //let html = responseText;

    setResponseChunks([{ text: html, typw: 'text' }]);

    // chunks.forEach((chunk, index) => {
    //     if(index === 0){
    //         chunk = chunk.replace("## Odpowiedź:", "");
    //     }
    //     //let html = chunk.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    //     let html = chunk;
    //     const countOne = (html.match(/<strong>/g) || []).length;
    //     const countTwo = (html.match(/<\/strong>/g) || []).length;

    //     if (countOne > countTwo) {
    //         html += "</strong>";
    //     }

    //     responseChunks.push({ text: html, typw: "text" });

    //     // if (index % 2 === 0) {
    //     //     responseChunks.push({ text: chunk, typw: "text" });
    //     // } else {
    //     //     responseChunks.push({ text: chunk, typw: "code" });
    //     // }
    // });
    // setResponseChunks(responseChunks);
  }, [responseText]);

  return (
    <Box>
      {responseChunks.map((chunk, index) => {
        if (chunk.typw === 'text') {
          return (
            <div
              style={{
                fontSize: '14px',
                color: getCssVariable('--text-primary'),
                userSelect: blockedText ? 'none' : 'text',
              }}
              dangerouslySetInnerHTML={{ __html: chunk.text }}
              key={index}
            />
          );
        } else {
          return <CodeSnippet key={index} codeSnippet={chunk.text} />;
        }
      })}
    </Box>
  );
};

export default BotResponsre;
