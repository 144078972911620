import { Box, Button, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { addDoc, collection, getDocs, updateDoc } from 'firebase/firestore';
import { firestore } from '../../../configs/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/Auth';
import UserWithPriceForm, { FormUser } from './UserWithPriceAddition';
import UserList from './UserList';

const AdminPage = () => {
  const { userRole } = useContext(AuthContext);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const [showList, setShowList] = useState(false);

  const navigate = useNavigate();

  const createFormUser = async (formUser: FormUser) => {
    if (formUser != null) {
      setLoading(true);

      const usersRef = collection(firestore, 'Users');
      const querySnapshot = await getDocs(usersRef);

      // Find existing user with matching email
      const matchingDoc = querySnapshot.docs.find(
        (doc) =>
          doc.data().email?.toLowerCase() === formUser.email.toLowerCase()
      );

      const trialEndDate = new Date();
      trialEndDate.setDate(trialEndDate.getDate() + formUser.trialDays);

      // Prepare user data (excluding trialDays)
      const userData: Partial<
        FormUser & { trial_ended_at: Date; user_price_setup_by_admin: Date }
      > = {
        ...formUser,
        email: formUser.email.toLowerCase(),
        trial_ended_at: trialEndDate,
        user_price_setup_by_admin: new Date(),
      };
      delete userData.trialDays;

      if (matchingDoc) {
        // Update existing user
        const existingData = matchingDoc.data();
        userData.description = existingData.description
          ? `${existingData.description}\n${formUser.description}`
          : formUser.description;

        await updateDoc(matchingDoc.ref, userData);
        setText('Zaktualizowano użytkownika');
      } else {
        // Create new user
        await addDoc(usersRef, userData);
        setText('Dodano użytkownika');
      }

      setLoading(false);
    }
  };

  const handleFormUserClick = (formUser: FormUser) => {
    createFormUser(formUser);
  };

  useEffect(() => {
    if (userRole !== 1134) {
      navigate('/');
    } else {
      setLoadingRoles(false);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Grid
      height={'100vh'}
      width={'calc(100vw - 100px)'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      alignContent={'center'}
    >
      <Button
        sx={{ position: 'absolute', top: '0', right: '0' }}
        onClick={() => navigate('/mnb/czat')}
      >
        Konwersacje
      </Button>
      {loadingRoles ? (
        <></>
      ) : (
        <Box>
          {showList ? (
            <>
              <Typography variant={'h3'} fontSize="16px" textAlign={'center'}>
                {text}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <UserWithPriceForm
                    onClick={handleFormUserClick}
                    loading={loading}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <UserList />
          )}
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setShowList(!showList);
            }}
          >
            {showList ? 'Lista użytkowników' : 'Ustal cenę i okres próbny'}
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default AdminPage;
