import { useTranslation } from "react-i18next";
import ServiceTile from "../../services/ServiceTile";
import img from "../../../../../../assets/images/hosting4.svg";
import img2 from "../../../../../../assets/images/web4.svg";
import img3 from "../../../../../../assets/images/app4.svg";
import img4 from "../../../../../../assets/images/consultation4.svg";

const ServiceTileSection = () => {
  const { t } = useTranslation();

  return (
    <section data-aos="zoom-in-down">
      <div className="my-4 py-4">
        <h4
          id={"narzedzia-h4"}
          className="my-2 text-center text-3xl font-bold"
          style={{ color: "white" }}
        >
          {t("start:services.tools")}
        </h4>

        <div className="flex justify-center">
          <div className="w-24 border-b-4" style={{ color: "white" }}></div>
        </div>
      </div>

      <div className="px-1" data-aos="fade-down" data-aos-delay="600">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5">
          <ServiceTile
            title={t("start:services.serviceTileOneTitle")}
            text={t("start:services.serviceTileOneText")}
            image={img}
          />
          <ServiceTile
            title={t("start:services.serviceTileTwoTitle")}
            text={t("start:services.serviceTileTwoText")}
            image={img2}
          />
          <ServiceTile
            title={t("start:services.serviceTileThreeTitle")}
            text={t("start:services.serviceTileThreeText")}
            image={img3}
          />
          <ServiceTile
            title={t("start:services.serviceTileFourTitle")}
            text={t("start:services.serviceTileFourText")}
            image={img4}
          />
        </div>
      </div>
    </section>
  );
};

export default ServiceTileSection;
