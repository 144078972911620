import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { auth, firestore } from '../../../../configs/firebaseConfig';
import { getUidByEmail } from 'api/get-uid';

export interface SubscriptionDetails {
  created: Date;
  current_period_end: Date;
  current_period_start: Date;
  cancel_at_period_end?: boolean;
}

export const getPremiumStatus =
  async (): Promise<SubscriptionDetails | null> => {
    const userId = await getUidByEmail('Customers', auth.currentUser?.email);
    if (!userId) throw new Error('User not logged in');
    const subscriptionsRef = collection(
      firestore,
      'Customers',
      userId,
      'subscriptions'
    );
    const q = query(
      subscriptionsRef,
      where('status', 'in', ['trialing', 'active'])
    );

    return new Promise<SubscriptionDetails | null>((resolve, reject) => {
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          if (snapshot.docs.length === 0) {
            resolve(null);
          } else {
            const doc = snapshot.docs[0];
            const data = doc.data();
            const subscriptionDetails: SubscriptionDetails = {
              created: data.created ? data.created?.toDate() : new Date(),
              current_period_end: data.current_period_end
                ? data.current_period_end?.toDate()
                : new Date(),
              current_period_start: data.current_period_start
                ? data.current_period_start.toDate()
                : new Date(),
              cancel_at_period_end: data.cancel_at_period_end ?? false,
            };
            resolve(subscriptionDetails);
          }
          unsubscribe();
        },
        reject
      );
    });
  };

export const getFreeTrialEndDate = async () => {
  const userId = await getUidByEmail('Users', auth.currentUser?.email);
  if (!userId) throw new Error('User not logged in');

  const userRef = doc(firestore, 'Users', userId);
  const userDoc = await getDoc(userRef);
  const data = userDoc.data();
  if (!data) throw new Error('User data not found');

  return data.trial_ended_at ? data.trial_ended_at.toDate() : new Date();
};
