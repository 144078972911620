import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet, useNavigate } from 'react-router-dom';
import colors from '../../../configs/colorConfig';
import styles from './styles.module.scss';

const EmptyLayout = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <div className={styles.container}>
        <Box
          component={'button'}
          position={'absolute'}
          top={'10px'}
          left={'10px'}
          textTransform={'none'}
          onClick={() => {
            navigate('/');
          }}
        >
          <Typography
            fontSize={'24px'}
            fontFamily={'Lato'}
            fontWeight={900}
            color={colors.primary}
          >
            PrawMi
          </Typography>
        </Box>
        <Outlet />
      </div>
    </Box>
  );
};

export default EmptyLayout;
