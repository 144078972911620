import { useStripe, useElements } from '@stripe/react-stripe-js';
import { Box, Button, CircularProgress } from '@mui/material';
import { auth, firestore } from '../../../../configs/firebaseConfig';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { getCssVariable } from 'styles/getVariables';
import stripeConfig from '../../../../secrets/stripeConfig.json';
import styles from './styles.module.scss';
import moment from 'moment';
// import { getFunctions, httpsCallable } from 'firebase/functions';
import { getUidByEmail } from 'api/get-uid';
import fetchDefaultFirebasePrice from 'settings-module/functions/fetchFirebaseDefaults';

type Props = {
  email: string;
  trialEndDate: Date | undefined;
  subscribtionCanceled: boolean;
  currentPeriodEnd?: Date;
};

const SubscribtionPaymentPanel = ({
  email,
  trialEndDate,
  subscribtionCanceled,
  currentPeriodEnd,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [loadingDefaultPrice, setLoadingDefaultPrice] = useState(false);
  const [userPrice, setUserPrice] = useState<number | null>(null);
  const [defaultFirebasePrice, setDefaultFirebasePrice] = useState<number>(0);

  useEffect(() => {
    setLoadingDefaultPrice(true);

    Promise.all([checkUserPrice(), fetchDefaultFirebasePrice()]).then(
      ([userPrice, defaultParams]) => {
        setUserPrice(userPrice);
        setDefaultFirebasePrice(defaultParams.price);
        setLoadingDefaultPrice(false);
      }
    );
  }, []);



  async function createCheckoutSession(
    customersUid: string,
    userFirebasePrice: number | null
  ) {
    const docRef = await addDoc(
      collection(
        firestore,
        'Customers',
        `${customersUid}`,
        'checkout_sessions'
      ),
      {
        mode: 'subscription',
        line_items: [
          {
            price_data: {
              currency: 'PLN',
              unit_amount: userFirebasePrice ? userFirebasePrice * 100 : defaultFirebasePrice * 100,
              recurring: {
                interval: 'month',
              },
              product: stripeConfig.productId,
            },
            quantity: 1,
          },
        ],
        success_url: window.location.origin + '/subskrypcja/aktywowana',
        cancel_url: window.location.origin + '/czat',
      }
    );

    onSnapshot(
      doc(
        firestore,
        'Customers',
        `${customersUid}`,
        'checkout_sessions',
        docRef.id
      ),
      (doc) => {
        if (doc.data()?.url) {
          window.location.href = doc.data()?.url;
        }
      }
    );
  }

  async function checkUserPrice() {
    setLoadingPrice(true);
    const uid = await getUidByEmail('Users', auth.currentUser?.email);
    if (!uid) return null;
    const user = await getDoc(doc(collection(firestore, 'Users'), uid));
    setLoadingPrice(false);
    return user.data()?.price ?? null;
  }

  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return <div></div>;
  }

  const handleSubmit = async (e: any) => {
    const uid = await getUidByEmail('Users', auth.currentUser?.email);
    if (uid) {
      setLoading(true);

      // Get customer UID from Customers collection
      const customersSnapshot = await getDocs(
        query(
          collection(firestore, 'Customers'),
          where('email', '==', auth.currentUser?.email)
        )
      );

      const customerDoc = customersSnapshot.docs[0];
      if (!customerDoc) {
        console.error('Customer not found');
        setLoading(false);
        return;
      }

      await createCheckoutSession(customerDoc.id, userPrice);
    }
  };

  return (
    <>
      <div className={styles.container}>
        {!(subscribtionCanceled === true && currentPeriodEnd) && (
          <h2 className={styles.heading}>Nie posiadasz ważnej subskrybcji</h2>
        )}
        <div className={styles.tabContainer}></div>
        <div className={styles.card}>
          <p className={styles.description}>
            {`Uzyskaj dostęp do aplikacji dla konta ${email}`}
          </p>
          {!loadingPrice && !loadingDefaultPrice ? (
            <>
              {subscribtionCanceled === true && currentPeriodEnd && (
                <p
                  className={styles.description}
                  style={{ color: 'firebrick' }}
                >
                  {`Twoja subskrypcja została anulowana. Utracisz dostęp do aplikacji  dnia ${moment(currentPeriodEnd).format('DD.MM.yyyy')}`}
                </p>
              )}
              <p className={styles.description} style={{ marginBottom: '3px' }}>
                {`Koszt subskrypcji`}
              </p>
              {userPrice && userPrice < defaultFirebasePrice && (
                <p
                  className={styles.description}
                  style={{
                    marginBottom: '3px',
                    textDecoration: 'line-through',
                  }}
                >
                  {`${defaultFirebasePrice} zł + VAT / miesiąc`}
                </p>
              )}
              <p
                className={styles.description}
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  marginTop: '1px',
                }}
              >
                {`${userPrice ?? defaultFirebasePrice} zł + VAT / miesiąc`}
              </p>
            </>
          ) : (
            <div
              style={{
                padding: '20px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircularProgress size={20} />
            </div>
          )}
          <div className={styles.buttonContainer}>
            {!loading ? (
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                fullWidth
              >
                Subskrybuj
              </Button>
            ) : (
              <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <CircularProgress
                  size={30}
                  sx={{ color: getCssVariable('--text-primary') }}
                />
              </Box>
            )}
          </div>

          {trialEndDate && trialEndDate > new Date() && (
            <div className={styles.freeTrialDateContainer}>
              <p style={{ fontSize: '16px' }}>
                <strong>
                  Darmowy okres próbny kończy się dnia{' '}
                  {moment(trialEndDate).format('DD.MM.yyyy')}{' '}
                </strong>
              </p>
            </div>
          )}

          <ul className={styles.featuresList}>
            <p>
              <strong>Subskrypcja zapewnia:</strong>
            </p>

            <li>dostęp do czatu PrawMi</li>
            <li>sugerowanie źródeł do zadanego pytania</li>
            <li>wyszukiwanie orzeczeń do artykułów w kontekście konwersacji</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SubscribtionPaymentPanel;
