import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet } from 'react-router-dom';
import NavBar from './navbar/NavBar';
import styles from './styles.module.scss';

const PublicLayout = () => {
  return (
    <Box className={styles.container}>
      <CssBaseline />
      <Box>
        <Box component="main">
          <NavBar />
          <Box className={styles.content}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PublicLayout;
