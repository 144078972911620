import { useContext } from 'react';
import { TabsContext } from '../context/TabsProvider';

const useTabs = () => {
  const {
    tabs,
    addRuling,
    setRulingList,
    removeRuling,
    displayed,
    setDisplayedCase,
    modifyRuling,
    addSearchPanel,
  } = useContext(TabsContext);
  return {
    tabs,
    addRuling,
    setRulingList,
    removeRuling,
    displayed,
    setDisplayedCase,
    modifyRuling,
    addSearchPanel,
  };
};
export default useTabs;
