import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { auth, firestore } from '../../../../configs/firebaseConfig';
import { useParams } from 'react-router-dom';
import { getCssVariable } from 'styles/getVariables';

type Props = {
  onClose(): void;
  onSend(): void;
};
const ReportForm = ({ onClose, onSend }: Props) => {
  const [type, setType] = useState<'bugreport' | 'missingdoc' | 'other'>(
    'bugreport'
  );
  const [description, setDescription] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const descriptionRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    descriptionRef.current?.focus();
  }, []);

  const sendRaport = async () => {
    const docData = {
      type,
      description,
      timestamp: new Date(),
      authorName: auth.currentUser?.displayName,
      authorEmail: auth.currentUser?.email,
      conversationId: id ?? '',
    };
    setLoading(true);
    await addDoc(collection(firestore, 'Raports'), docData);
    setLoading(false);
    onSend();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h2 style={{ fontSize: '16px' }}>Zgłoś problem</h2>
      </Grid>
      {loading ? (
        <Grid
          item
          xs={12}
          justifyContent={'center'}
          display={'flex'}
          width={'100%'}
        >
          <Box>
            <Typography fontSize={'13px'}>Wysyłanie...</Typography>
          </Box>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <p style={{ fontSize: '13px', fontWeight: 'bold' }}>
              Rodzaj problemu
            </p>
            <Select
              value={type}
              size={'small'}
              onChange={(e) =>
                setType(e.target.value as 'bugreport' | 'missingdoc' | 'other')
              }
            >
              <MenuItem value="bugreport">Błąd w aplikacji</MenuItem>
              <MenuItem value="missingdoc">Brakujący dokument</MenuItem>
              <MenuItem value="other">Inne</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <p style={{ fontSize: '13px', fontWeight: 'bold' }}>Opis błędu</p>
            <TextField
              inputRef={descriptionRef}
              size={'small'}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={5}
              fullWidth
              placeholder="Opisz problem..."
            />
          </Grid>
          <Grid item xs={12} display={'flex'} justifyContent={'right'}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ marginRight: '10px' }}
              disabled={description.length === 0}
              onClick={() => sendRaport()}
            >
              Wyślij
            </Button>
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={() => {
                onClose();
              }}
            >
              Anuluj
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ReportForm;
