import { Box, CircularProgress, Typography } from '@mui/material';
// import axiosPrivate from '../../../../api/axios';
import { auth } from '../../../../configs/firebaseConfig';
import { RulingsSerachFilter } from '../../../models/RulingsSerachFilter';
import SearchRulingFilters from '../search-ruling-filters/SearchRulingFilters';
import { useState } from 'react';
import { RulingModel } from '../../../../models/RulingModel';
import useTabs from '../../../../hooks/useTabs';
import { useParams } from 'react-router-dom';
import axios from 'api/axios';


const SearchRulingPanel = () => {
  const [rulingsList, setRulingsList] = useState<RulingModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { addRuling, setDisplayedCase } = useTabs();
  const [searched, setSearched] = useState<boolean>(false);

  const mapJudgementType = (type: string) => {
    if (
      type.toLowerCase().includes('sentence') ||
      type.toLowerCase().includes('wyrok')
    ) {
      return 'Wyrok';
    } else if (
      type.toLowerCase().includes('order') ||
      type.toLowerCase().includes('postanowienie') ||
      type.toLowerCase().includes('decision')
    ) {
      return 'Postanowienie';
    } else if (
      type.toLowerCase().includes('uzasadbnienie') ||
      type.toLowerCase().includes('justification') ||
      type.toLowerCase().includes('reasons')
    ) {
      return 'Uzasadnienie';
    } else {
      return 'Inne';
    }
  };

  const handleSearch = async (filters: RulingsSerachFilter) => {
    setSearched(true);
    setLoading(true);
    const token = await auth?.currentUser?.getIdToken();

    const encodedQuery = encodeURIComponent(filters.name);
    // const encodedQuery = filters.name;
    
    try {
      const response = await axios
      .get(`/lawmodel/rulings/?query=${encodedQuery}&type=${''}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: '*',
          'content-type': 'application/json',
        },
      });

      if (response.data && response.data.length > 0) {
        const ordinances = response.data.map((m: any) => ({
          title: m.court_case || 'No case number',
          description: m.text_excerpt || '',
          type: 'ruling',
          url: m.link,
          id: m.id || crypto.randomUUID(),
          endIndex: m.end_index || -1,
          startIndex: m.start_index || -1,
          text: m.text_excerpt || '',
          judgmentDate: m.judgment_date || '',
          judgmentType: m.judgment_type || 'Unknown',
        }));
        setRulingsList(ordinances);
      } else {
        setRulingsList([]);
      }
    } catch (error) {
      console.error('Error fetching rulings:', error);
      setRulingsList([]);
    } finally {
      setLoading(false);
    }
  };

  const handleShowRuling = (rul: RulingModel) => {
    addRuling(rul, id);
    setDisplayedCase(rul);
  };

  return (
    <Box maxWidth={'100%'}>
      <SearchRulingFilters onSearch={handleSearch} />
      <Box>
        {loading && (
          <Box
            height={'100px'}
            width={'400px'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CircularProgress size={'32px'} />
          </Box>
        )}
      </Box>
      <Box>
        {rulingsList.length > 0 && (
          <Typography sx={{ marginTop: '30px', marginLeft: '5px' }}>
            Znaleziono następujące wyniki:
          </Typography>
        )}
        {rulingsList.length === 0 && searched && loading === false && (
          <Typography sx={{ marginTop: '30px', marginLeft: '5px' }}>
            Nie znaleziono wyników
          </Typography>
        )}
        <Box width={'100%'} marginTop={'20px'}>
          {rulingsList.map((rul, index) => {
            return (
              <Box
                component={'button'}
                sx={{
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'var(--hover-color)',
                  },
                }}
                key={index}
                width={'100%'}
                p={'10px'}
                borderBottom={'1px solid #DBDBDB'}
                onClick={() => {
                  handleShowRuling(rul);
                }}
                alignContent={'left'}
                alignItems={'left'}
              >
                <Box display={'flex'}>
                  <Typography align="left" sx={{ width: '200px' }}>
                    {rul.title}
                  </Typography>
                  <Typography align="left">{rul.judgmentDate}</Typography>
                </Box>
                <Typography align="left" fontSize={'12px'} fontWeight={500}>
                  {mapJudgementType(rul.judgmentType ?? '')}
                </Typography>
                <Typography 
                  align="left" 
                  fontSize={'14px'} 
                  sx={{ 
                    marginTop: '8px',
                    color: '#666',
                    maxHeight: '60px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical'
                  }}
                >
                  {rul.text}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default SearchRulingPanel;
