import { Box, Link } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

type Props = {
  showIcon?: boolean;
};

const OfficeLink: React.FC<Props> = ({ showIcon }) => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      {showIcon && (
        <MailOutlineIcon
          sx={{ fontSize: '1.5rem', color: 'gray', marginRight: '10px' }}
        />
      )}
      <Link
        sx={{
          color: 'gray',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
        underline="none"
        href="mailto:biuro@prawmi.pl"
      >
        biuro@prawmi.pl
      </Link>
    </Box>
  );
};

export default OfficeLink;
