import { useEffect, useState } from 'react';

const useDarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Initialize state based on stored preference or system preference
    const userPreferedTheme = parseInt(
      localStorage.getItem('userPreferedTheme') || '0'
    );

    if (userPreferedTheme === 1) return true;
    if (userPreferedTheme === 2) return false;

    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  useEffect(() => {
    // Only set up system preference listener if no user preference is set
    const userPreferedTheme = parseInt(
      localStorage.getItem('userPreferedTheme') || '0'
    );

    if (userPreferedTheme !== 0) return;

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e: MediaQueryListEvent) => {
      setIsDarkMode(e.matches);
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  const setUserThemePreference = (preference: 0 | 1 | 2) => {
    localStorage.setItem('userPreferedTheme', preference.toString());
    if (preference === 1) {
      setIsDarkMode(true);
    } else if (preference === 2) {
      setIsDarkMode(false);
    } else {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      setIsDarkMode(mediaQuery.matches);
    }
  };

  return { isDarkMode, setUserThemePreference };
};

export default useDarkMode;
