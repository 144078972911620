import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import styles from './styles.module.scss';
import SingleLineSchema from './components/SingleLineSchema';
import ParallelSchema from './components/ParallelSchema';

const DescriptionSchema: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <div
      style={{
        marginTop: '-100px',
        background: 'var(--background-start-primary)',
      }}
    >
      <div className={styles.container}>
        <svg className={styles.svgImg} viewBox="0 0 500 100" width="100%">
          <path
            d="M0,0 C150,100 350,0 500,30 L500,0 L0,0 Z"
            style={{ fill: 'var(--background-start-primary)' }}
          />
        </svg>

        {isSmallScreen ? (
          <SingleLineSchema />
        ) : (
          <ParallelSchema
            isSmallScreen={isSmallScreen}
            isMediumScreen={isMediumScreen}
          />
        )}

        <svg
          className={styles.svgImg2}
          viewBox="0 0 500 50"
          width="100%"
          style={{
            transform: 'rotate(180deg)',
            position: 'absolute',
            bottom: -2,
          }}
        >
          <path
            d="M0,0 C150,50 350,0 500,15 L500,0 L0,0 Z"
            style={{ fill: 'var(--background-start-primary)' }}
          />
        </svg>
      </div>
    </div>
  );
};

export default DescriptionSchema;
