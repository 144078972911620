import React, { useCallback } from 'react';
import LetterView, { LetterModel } from './LetterView';
import { Box, IconButton } from '@mui/material';
import assets from '../../../../../../assets';
import { PathModel } from '../../LawPanel';

export interface PointModel {
  name: string;
  text: string;
  highlighted?: boolean;
  letters: LetterModel[];
  numberOfRulings: number;
}

type Props = {
  point: PointModel;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rulingsNumber: number,
    path: PathModel
  ) => void;
  paragraphId: string;
};

const PointView: React.FC<Props> = React.memo(
  ({ point, onClick, paragraphId }: Props) => {
    const handleClick = useCallback(
      (
        event: React.MouseEvent<HTMLButtonElement>,
        numberOfRulings: number,
        path?: PathModel | undefined
      ) => {
        let model: PathModel = {
          article: '',
          paragraph: '',
          point: '',
        };

        if (path != null) {
          model = path;
        }
        model.point = point.name;

        onClick(event, numberOfRulings, model);
      },
      [onClick]
    );

    return (
      <>
        <div
          style={{ marginLeft: '20px', display: 'flex', alignItems: 'start' }}
        >
          {point.name !== '' ? (
            <>
              {point.numberOfRulings > 0 ? (
                <IconButton
                  id={paragraphId + point.name}
                  onClick={(e) => {
                    handleClick(e, point.numberOfRulings);
                  }}
                  sx={{ padding: '6px' }}
                >
                  <Box
                    borderRadius={'5px'}
                    component={'img'}
                    src={assets.icons.hammerBlack}
                    width="15px"
                    height={'15px'}
                  />
                </IconButton>
              ) : (
                <IconButton disabled={true} sx={{ padding: '6px' }}>
                  <Box width="15px" height={'15px'} />
                </IconButton>
              )}
              <div>
                <div
                  style={{ paddingTop: '4px', paddingBottom: '4px' }}
                  dangerouslySetInnerHTML={{ __html: point.text }}
                />
              </div>
            </>
          ) : null}
        </div>
        {point.letters.map((letter, indexLetter) => {
          return (
            <LetterView
              key={indexLetter}
              letter={letter}
              pointId={paragraphId + point.name}
              onClick={handleClick}
            />
          );
        })}
      </>
    );
  }
);

export default PointView;
