import { useEffect, useRef, useState, useMemo } from 'react';
import { LawModel, PartModel, dataToTree } from '../../../../models/LawModel';
import axiosPrivate from '../../../../api/axios';
import { auth } from '../../../../configs/firebaseConfig';
import ArticleView, { ArticleModel } from './components/law-parts/ArticleView';
import useTextHighlight from '../../../../hooks/useTextHighlight';
import { RulingPopoverModel } from '../right-side-bar/RightSideBar';
import useTabs from '../../../../hooks/useTabs';
import LoadingWrapper from '../../../../components/loading-wrapper/LoadingWrapper';
import axios, { CancelTokenSource } from 'axios';
import React from 'react';
import { useCallback } from 'react';

export interface LawPanelData {
  listOfParts: PartModel[];
  listOfArticleNames: string[];
  highlightedArticle: string;
}

export interface PathModel {
  article: string;
  paragraph?: string;
  point?: string;
  letter?: string;
}

type Props = {
  law: LawModel;
  onParagraphSet: (number: number) => void;
  lawPanelData: LawPanelData;
  onLawPanelDataSet: (data: LawPanelData) => void;
  onArticlePositionsSet: (
    positions: { name: string; position: number }[]
  ) => void;
  onLawHammerClick: (data: RulingPopoverModel) => void;
};

const LawPanel = ({
  law,
  onParagraphSet,
  lawPanelData,
  onLawPanelDataSet,
  onArticlePositionsSet,
  onLawHammerClick,
}: Props) => {
  const targetRefs = useRef<Array<HTMLDivElement | null>>([]);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const { blockedText } = useTextHighlight();
  const [listOfArticleModels, setListOfArticleModels] = useState<
    ArticleModel[]
  >([]);
  const { displayed } = useTabs();
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);

  const getData = async () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        'Operation canceled due to new request.'
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();

    const token = await auth?.currentUser?.getIdToken();
    setLoading(true);

    try {
      const response = await axiosPrivate.get(
        `/lawmodel/acts/text?unified_link=${law.url}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: '*',
            'content-type': 'application/json',
          },
          responseType: 'json',
          cancelToken: cancelTokenSourceRef.current.token,
        }
      );

      const rulingsPaths = Object.values(response.data.rulings_paths);
      const sections = dataToTree(
        response.data.law_structure,
        law,
        rulingsPaths
      );
      const listOfNames = sections.listOfArticles.map((m) => m.name);

      onLawPanelDataSet({
        listOfParts: sections.sections,
        listOfArticleNames: listOfNames,
        highlightedArticle: (displayed as LawModel).highlightedArticle ?? '',
      });

      setListOfArticleModels(sections.listOfArticles);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (listOfArticleModels.length > 0) {
      const elements = Array.from(
        document.getElementsByClassName('highlightedDiv')
      );
      if (elements.length > 0) {
        const position = elements[0].getBoundingClientRect();
        onParagraphSet(position.y - 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfArticleModels]);

  useEffect(() => {
    if (lawPanelData.listOfParts?.length > 0) {
      const list = lawPanelData.listOfArticleNames.map((name) => {
        const element = document.getElementById(
          name.replaceAll('.', '').replaceAll(' ', '')
        );
        return { name, position: element?.offsetTop ?? 0 };
      });
      onArticlePositionsSet(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lawPanelData.listOfArticleNames]);

  useEffect(() => {
    const fetchData = async () => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Component unmounted.');
      }

      const shouldFetchData =
        !lawPanelData.listOfParts?.length ||
        (displayed as LawModel).highlightedArticle !==
          lawPanelData.highlightedArticle;

      if (shouldFetchData) {
        await getData();
      } else {
        onLawPanelDataSet({
          ...lawPanelData,
          highlightedArticle: (displayed as LawModel).highlightedArticle ?? '',
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [law]);

  const handleClick = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement>,
      numberOfRulings: number,
      path: PathModel
    ) => {
      onLawHammerClick({
        endpoint: `Dz. U. z ${law.year} r. poz. ${law.number}`,
        path: path,
        anchor: event.currentTarget.id,
        numberOfRulings: numberOfRulings,
        law: law,
        loadedRulings: [],
        visibility: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onLawHammerClick]
  );

  const createRef = () => (element: HTMLDivElement | null) => {
    targetRefs.current[targetRefs.current.length] = element;
  };

  const renderItem = (item: any, index: number) => {
    const uniqueKey = `${item.type}-${item.content.name}-${index}-${item.content.id || ''}`;

    if (item.type === 'article') {
      return (
        <div key={uniqueKey} style={{ paddingTop: '5px' }}>
          <div
            id={item.content.name.replaceAll('.', '').replaceAll(' ', '')}
            ref={createRef()}
          />
          <ArticleView article={item.content} onClick={handleClick} />
        </div>
      );
    } else {
      if (item.content.name == null || item.content.name === '') {
        return null;
      } else {
        return (
          <div
            key={uniqueKey}
            style={{
              userSelect: blockedText ? 'none' : 'text',
              paddingTop: '30px',
              paddingBottom: '10px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <label
              style={{
                marginLeft: '20px',
                fontWeight: 'bold',
                fontSize: '18px',
                textAlign: 'center',
              }}
            >
              {item.content.name}
            </label>
          </div>
        );
      }
    }
  };

  const flattenedContent = useMemo(() => {
    const flattened: any[] = [];

    lawPanelData.listOfParts?.forEach((part) => {
      flattened.push({ type: 'part', content: part });
      part.books?.forEach((book) => {
        flattened.push({ type: 'book', content: book });
        book.titles?.forEach((title) => {
          flattened.push({ type: 'title', content: title });
          title.sections?.forEach((section) => {
            flattened.push({ type: 'section', content: section });
            section.chapters?.forEach((chapter) => {
              flattened.push({ type: 'chapter', content: chapter });
              chapter.subchapters?.forEach((subchapter) => {
                flattened.push({ type: 'subchapter', content: subchapter });
                subchapter.articles?.forEach((article) => {
                  flattened.push({ type: 'article', content: article });
                });
              });
            });
          });
        });
      });
    });

    return flattened;
  }, [lawPanelData.listOfParts]);

  return (
    <div style={{ display: 'flex', width: '100%' }} ref={componentRef}>
      <LoadingWrapper isLoading={loading} height={'300px'}>
        <div>
          <div style={{ userSelect: blockedText ? 'none' : 'text' }}>
            <label style={{ textAlign: 'center', display: 'block' }}>
              {law.title}
            </label>
            <label
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                display: 'block',
                fontSize: '20px',
              }}
            >
              {law.longTitle}
            </label>
          </div>
          <div style={{ height: '100%' }}>
            {flattenedContent.map((item, index) => renderItem(item, index))}
          </div>
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default React.memo(LawPanel);
