import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import { LawModel } from '../../../../models/LawModel';
import { RulingModel } from '../../../../models/RulingModel';
import RulingItem from './RulingItem';
import styles from './listOfSources.module.scss';
import { useRef, useEffect } from 'react';
import { getCssVariable } from 'styles/getVariables';

type Props = {
  rulings: RulingModel[];
  loading: boolean;
  onRulingSelect: (source: RulingModel) => void;
  onLawSelect: (source: LawModel) => void;
  expanded: boolean;
  onExpandedChange: (expanded: boolean) => void;
};

const ListOfDistinctRulings = ({
  rulings,
  loading,
  onRulingSelect,
  onLawSelect,
  expanded,
  onExpandedChange,
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const height = contentRef.current.scrollHeight;
      contentRef.current.style.setProperty('--expanded-height', `${height}px`);
    }
  }, [rulings]);

  return (
    <>
      <Box marginBottom={'10px'}>
        <Box
          sx={{
            borderRadius: '10px',
            border: '1px solid lightGray',
            padding: '10px',
            background: getCssVariable('--background-color-primary'),
          }}
        >
          <Box
            ref={contentRef}
            className={`${styles.textContainerDistinct} ${
              expanded ? styles.expanded : ''
            }`}
          >
            <Box
              className={styles.fadeOut}
              component={'button'}
              style={{ border: 'none' }}
              onClick={() => {
                onExpandedChange(!expanded);
              }}
            ></Box>
            {loading === false && rulings.length === 0 ? (
              <Box />
            ) : (
              <Box marginTop={'0px'}>
                <Divider />
              </Box>
            )}

            <Box>
              <Box
                component={'button'}
                style={{ border: 'none' }}
                onClick={() => {
                  onExpandedChange(!expanded);
                }}
              >
                {loading === false && rulings.length === 0 ? (
                  <Box />
                ) : (
                  <Typography>Interesujące orzeczenia: </Typography>
                )}
              </Box>
              {loading === true && (
                <Box
                  paddingBottom={'15px'}
                  paddingTop={'5px'}
                  display={'flex'}
                  alignContent={'center'}
                  alignItems={'center'}
                  justifyContent={'left'}
                >
                  <CircularProgress
                    size={'22px'}
                    sx={{
                      marginRight: '10px',
                      marginLeft: '20px',
                    }}
                  />
                  <Typography sx={{ fontStyle: 'italic', color: 'darkgrey' }}>
                    Wyszukiwanie orzeczeń
                  </Typography>
                </Box>
              )}
            </Box>

            {rulings &&
              rulings.map((ruling, rulIndex) => (
                <div key={rulIndex}>
                  <Box paddingLeft={'30px'}>
                    {rulIndex > 0 && <Divider />}

                    <RulingItem
                      source={ruling}
                      gridProps={{ mb: '20px' }}
                      onSourceSelect={(s) => {
                        onRulingSelect(s);
                      }}
                    />
                  </Box>
                </div>
              ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ListOfDistinctRulings;
