import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../configs/firebaseConfig';

export const getUidByEmail = async (
  collectionName: 'Customers' | 'Users',
  email: string | null | undefined
): Promise<string | null> => {
  if (!email) return null;

  try {
    const querySnapshot = await getDocs(
      query(collection(firestore, collectionName), where('email', '==', email))
    );

    if (querySnapshot.empty) {
      console.log(
        `No document found in ${collectionName} with email: ${email}`
      );
      return null;
    }

    return querySnapshot.docs[0].id;
  } catch (error) {
    console.error(`Error getting UID from ${collectionName}:`, error);
    return null;
  }
};
