import { Box, Button, IconButton } from '@mui/material';
import { ConversationModel } from '../../../../models/ConversationModel';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useState } from 'react';

type Props = {
  conversation: ConversationModel;
  index: number;
  onDeleteClick: (conversation: any) => void;
  onClick: (conversation: any) => void;
};
const ConversationButton = ({
  conversation,
  index,
  onDeleteClick,
  onClick,
}: Props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <Box
      key={index}
      style={{ display: 'flex', justifyContent: 'space-between' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      width={'320px'}
    >
      <Button
        color={'secondary'}
        onClick={() => {
          onClick(conversation);
        }}
        style={{
          fontSize: '12px',
          textTransform: 'none',
          height: '35px',
          width: '290px',
          justifyContent: 'flex-start',
        }}
      >
        {conversation.title.substring(0, 40) + '...'}
      </Button>
      {isHovered && (
        <IconButton
          onClick={() => {
            onDeleteClick(conversation);
          }}
          sx={{ marginTop: '-2px' }}
        >
          <DeleteOutlineIcon sx={{ fontSize: '18px' }} />
        </IconButton>
      )}
    </Box>
  );
};

export default ConversationButton;
