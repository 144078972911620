import React, { useCallback, useState, createContext } from 'react';

interface PropsBasicDataProvider {
  children: React.ReactNode;
}

interface ContextDataModel {
  id: string;
  setId: (id: string) => void;
  vector: string;
  setVector: (v: string) => void;
}

export const ConversationContext: React.Context<ContextDataModel> =
  createContext({
    id: '' as string,
    setId: (error: any) => {},
    vector: '' as string,
    setVector: (v: any) => {},
  });

export const ConversationProvider: any = ({
  children,
}: PropsBasicDataProvider) => {
  const [localId, setLocoalId] = useState<string>('');
  const [localVector, setLocalVector] = useState('');

  const setId = (r: string) => {
    setLocoalId(r);
    return r;
  };

  const setVector = (v: string) => {
    setLocalVector(v);
    return v;
  };

  const contextValue = {
    id: localId,
    setId: useCallback((error: any) => setId(error), []),
    vector: localVector,
    setVector: useCallback((v: any) => setVector(v), []),
  };

  return (
    <ConversationContext.Provider value={contextValue}>
      {children}
    </ConversationContext.Provider>
  );
};
