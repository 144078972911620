import { CircularProgress, Grid, Link, TextFieldProps } from '@mui/material';
import { Button, TextField, Box, Typography } from '@mui/material';
import { useState } from 'react';
import assets from '../../../../assets';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, provider } from '../../../../configs/firebaseConfig';
import colors from '../../../../configs/colorConfig';
import AuthenticationBox from '../../molecules/authentication-box/AuthenticationBox';

const FormField = (props: TextFieldProps) => (
  <TextField
    {...props}
    margin="normal"
    required
    fullWidth
    // onChange={(e) => setUsername(e.target.value)}
  >
    {props.children}
  </TextField>
);

const LoginPanel = () => {
  const [isLoadingCredentials, setIsLoadingCredentials] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [credentials, setCredentials] = useState<{
    login: string;
    password: string;
  }>({ login: '', password: '' });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  const handleCredentialsChange = (
    value: string,
    key: 'login' | 'password'
  ) => {
    setErrorMessage('');
    setCredentials({ ...credentials, [key]: value });
  };

  const handleLoginClick = async () => {
    setIsLoadingCredentials(true);
    await signInWithEmailAndPassword(
      auth,
      credentials.login,
      credentials.password
    )
      .then((data) => {
        navigate('/czat');
      })
      .catch((error) => {
        setErrorMessage('Niepoprawne dane logowania');
      });
    setIsLoadingCredentials(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleLoginClick();
    }
  };

  const handleGooleLoginClick = async () => {
    setIsLoadingGoogle(true);
    await signInWithPopup(auth, provider).then((data) => {
      navigate('/czat');
    });
    setIsLoadingGoogle(false);
  };

  return (
    <AuthenticationBox title="Logowanie" errorMessage={errorMessage}>
      <Box>
        <FormField
          label="Email"
          value={credentials.login}
          onChange={(e) => handleCredentialsChange(e.target.value, 'login')}
          autoFocus
        />
        <FormField
          label="Hasło"
          value={credentials.password}
          onChange={(e) => handleCredentialsChange(e.target.value, 'password')}
          autoComplete="current-password"
          onKeyDown={handleKeyDown}
          type="password"
        />
        {isLoadingCredentials ? (
          <Grid
            container
            width={'100%'}
            marginTop={'10px'}
            height={'40px'}
            display={'flex'}
            justifyContent={'center'}
          >
            <CircularProgress size={'22px'} sx={{ margin: 'auto' }} />
          </Grid>
        ) : (
          <Button
            fullWidth
            variant="contained"
            onClick={() => handleLoginClick()}
            sx={{
              mt: 3,
              height: '40px',
              backgroundColor: colors.primary,
            }}
            disabled={isLoadingCredentials || isLoadingGoogle}
          >
            {/* <Typography padding="2px" fontSize={"12px"} fontWeight={"500"}>Zaloguj</Typography> */}
            Zaloguj
          </Button>
        )}
        {isLoadingGoogle ? (
          <Grid
            container
            width={'100%'}
            marginTop={'10px'}
            height={'40px'}
            display={'flex'}
            justifyContent={'center'}
          >
            <CircularProgress size={'22px'} sx={{ margin: 'auto' }} />
          </Grid>
        ) : (
          <Button
            fullWidth
            variant="contained"
            onClick={() => handleGooleLoginClick()}
            sx={{
              mt: 3,
              backgroundColor: 'white',
              height: '40px',
              boxShadow: '0px 0px 0px 0px',
              border: '1px solid black',
              color: 'black',
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
            disabled={isLoadingCredentials || isLoadingGoogle}
            startIcon={
              <img
                alt={'googleIcon'}
                src={assets.icons.googleIcon}
                style={{ width: '20px', height: '20px' }}
              />
            }
          >
            Zaloguj przez Google
          </Button>
        )}

        <Grid container>
          <Grid item xs display={'flex'} justifyContent={'center'} py={'20px'}>
            <Typography sx={{ fontSize: '13px' }}>Nie masz konta?</Typography>
            <Link
              href="/rejestracja"
              sx={{
                fontSize: '13px',
                textDecoration: 'none',
                color: colors.primary,
                marginLeft: '5px',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
              variant="body2"
            >
              Zarejestruj się!
            </Link>
          </Grid>
        </Grid>
      </Box>
    </AuthenticationBox>
  );
};

export default LoginPanel;
