import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/layout/sidebar/Sidebar";
import styles from "./styles.module.scss";

const DashboardLayout = () => {
  return (
    <Box className={styles.container}>
      <CssBaseline />
      <Box position="absolute" className="sidebar">
        <Sidebar />
      </Box>
      <Box marginLeft={"81px"}>
        <Box className={styles.content} component="main">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
