import { Box, Button, ButtonProps, styled } from '@mui/material';
import style from './switchButtons.module.scss';

const StyledButton = styled(Button)<ButtonProps>({
  borderRadius: '20px',
  padding: '5px',
  fontSize: '12px',
  marginRight: '5px',
  textTransform: 'none',
});

type Props = {
  titleOne: string;
  titleTwo: string;
  onChange: (value: 'one' | 'two') => void;
  value: 'one' | 'two';
  margin?: string;
};

const SwitchButtons = ({
  titleOne,
  titleTwo,
  onChange,
  value,
  margin,
}: Props) => {
  return (
    <Box className={style.mainDiv} sx={{ margin: margin ?? '' }}>
      <StyledButton
        variant={value === 'one' ? 'contained' : 'outlined'}
        onClick={() => {
          onChange('one');
        }}
      >
        {titleOne}
      </StyledButton>
      <StyledButton
        variant={value === 'two' ? 'contained' : 'outlined'}
        onClick={() => {
          onChange('two');
        }}
      >
        {titleTwo}
      </StyledButton>
    </Box>
  );
};

export default SwitchButtons;
