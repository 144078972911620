import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import fetchDefaultFirebasePrice from 'settings-module/functions/fetchFirebaseDefaults';

export interface FormUser {
  email: string;
  firm: string;
  description: string;
  price: number;
  trialDays: number;
}

type Props = {
  onClick: (user: FormUser) => void;
  loading: boolean;
};

const UserWithPriceForm = ({ onClick, loading }: Props) => {
  const [createdUser, setCreatedUser] = useState<FormUser>({
    email: '',
    firm: '',
    description: '',
    price: 0,
    trialDays: 0,
  });

  useEffect(() => {
    fetchDefaultFirebasePrice().then((defaultParams) => {
      setCreatedUser({
        ...createdUser,
        price: defaultParams.price,
        trialDays: defaultParams.days,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      width={'400px'}
      height={'550px'}
      padding="30px"
      style={{
        border: '1px solid lightGray',
        borderRadius: '5px',
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <Box width={'100%'} display={'flex'} justifyContent={'center'}>
        <Typography fontWeight={'600'}>
          {' '}
          Zmodyfikuj cenę / okres triala dla użytkownika
        </Typography>
      </Box>
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        marginTop={'20px'}
        flexDirection={'column'}
      >
        <Typography variant={'body2'}>Email *</Typography>
        <TextField
          size={'small'}
          fullWidth
          sx={{ marginBottom: '20px' }}
          value={createdUser.email}
          onChange={(e) => {
            setCreatedUser({ ...createdUser, email: e.target.value });
          }}
          placeholder="podaj adres email"
        />

        <Typography variant={'body2'}>Opis</Typography>
        <TextField
          size={'small'}
          fullWidth
          value={createdUser.description}
          sx={{ marginBottom: '10px' }}
          onChange={(e) => {
            setCreatedUser({ ...createdUser, description: e.target.value });
          }}
          placeholder="podaj opis (opcjonalnie)"
        />

        <Typography variant={'body2'}>Cena</Typography>
        <TextField
          size={'small'}
          fullWidth
          type="number"
          value={createdUser.price}
          sx={{ marginBottom: '10px' }}
          onChange={(e) => {
            setCreatedUser({ ...createdUser, price: Number(e.target.value) });
          }}
          placeholder="podaj cenę"
        />

        <Typography variant={'body2'}>Dni próbne</Typography>
        <TextField
          size={'small'}
          fullWidth
          type="number"
          value={createdUser.trialDays}
          sx={{ marginBottom: '10px' }}
          onChange={(e) => {
            setCreatedUser({
              ...createdUser,
              trialDays: Number(e.target.value),
            });
          }}
          placeholder="podaj ilość dni próbnych"
        />
      </Box>
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        marginTop={'20px'}
      >
        <Button
          disabled={createdUser.email.trim() === ''}
          fullWidth
          variant="contained"
          onClick={() => {
            onClick(createdUser);
          }}
        >
          {loading ? (
            <CircularProgress size={20} color={'inherit'} />
          ) : (
            'Utwórz / aktualizuj dane użytkownika'
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default UserWithPriceForm;
