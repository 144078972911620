import { CircularProgress, Grid, TextFieldProps } from '@mui/material';
import { Button, TextField, Box } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, firestore } from '../../../configs/firebaseConfig';
import colors from '../../../configs/colorConfig';
import AuthenticationBox from 'public-module/components/molecules/authentication-box/AuthenticationBox';
import { addDoc, collection, getDocs, updateDoc } from 'firebase/firestore';

const FormField = (props: TextFieldProps) => (
  <TextField
    {...props}
    margin="normal"
    required={props.required ?? true}
    fullWidth
  >
    {props.children}
  </TextField>
);

type Props = {
  email: string | null;
};
const UserSetupPanel = ({ email }: Props) => {
  const [isLoadingCredentials, setIsLoadingCredentials] = useState(false);
  const [credentials, setCredentials] = useState<{
    password: string;
    repeatPassword: string;
    firstName: string;
    lastName: string;
    promoCode: string;
  }>({
    password: '',
    repeatPassword: '',
    firstName: '',
    lastName: '',
    promoCode: '',
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  const handlePasswordSetup = async (e: any) => {
    e.preventDefault();
    setIsLoadingCredentials(true);
    setErrorMessage('');

    // Validation checks
    if (credentials.password !== credentials.repeatPassword) {
      setErrorMessage('Hasła nie są takie same');
      setIsLoadingCredentials(false);
      return;
    }
    if (credentials.password.length < 6) {
      setErrorMessage('Hasło musi mieć co najmniej 6 znaków');
      setIsLoadingCredentials(false);
      return;
    }

    if (email) {
      try {
        await createUserWithEmailAndPassword(auth, email, credentials.password);

        await createFirebaseUser();

        navigate('/czat');
      } catch (error: any) {
        console.error(error);
        if (error.code === 'auth/email-already-in-use') {
          setErrorMessage('Ten adres email jest już używany');
        } else {
          setErrorMessage('Wystąpił błąd podczas tworzenia konta');
        }
      } finally {
        setIsLoadingCredentials(false);
      }
    }
  };

  const createFirebaseUser = async () => {
    // UPSERT

    const usersRef = collection(firestore, 'Users');
    const querySnapshot = await getDocs(usersRef);

    // Check if user already exists
    const matchingDoc = querySnapshot.docs.find(
      (doc) => doc.data().email?.toLowerCase() === email?.toLowerCase()
    );

    const hyperparametersRef = collection(firestore, 'Hyperparameters');
    const hyperparametersDoc = await getDocs(hyperparametersRef);
    const hyperparametersData = hyperparametersDoc.docs
      .find((doc) => doc.id === 'default')
      ?.data();
    const trialDays = hyperparametersData?.default_trial_days;
    const currentDate = new Date();
    const endTrialDate = new Date(currentDate);
    const addDays = credentials.promoCode.toLowerCase() === 'lexera' ? 7 : 0;
    endTrialDate.setDate(currentDate.getDate() + trialDays + addDays);

    const userData = {
      email: email?.toLowerCase(),
      firstName: credentials.firstName,
      lastName: credentials.lastName,
      promoCode: credentials.promoCode.toLowerCase(),
      trial_ended_at: endTrialDate,
      createdAt: new Date(),
    };

    if (matchingDoc) {
      // Update existing user
      await updateDoc(matchingDoc.ref, userData);
    } else {
      // Create new user
      await addDoc(usersRef, userData);
    }
  };

  return (
    <AuthenticationBox title="Podaj dane" errorMessage={errorMessage}>
      <Box
        sx={{
          marginTop: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box>
          <FormField
            label="Hasło"
            value={credentials.password}
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
            type="password"
          />
          <FormField
            label="Powtórz hasło"
            value={credentials.repeatPassword}
            onChange={(e) =>
              setCredentials({ ...credentials, repeatPassword: e.target.value })
            }
            type="password"
          />
          <FormField
            label="Imię"
            required={false}
            value={credentials.firstName}
            onChange={(e) =>
              setCredentials({ ...credentials, firstName: e.target.value })
            }
          />
          <FormField
            label="Nazwisko"
            value={credentials.lastName}
            required={false}
            onChange={(e) =>
              setCredentials({ ...credentials, lastName: e.target.value })
            }
          />

          <FormField
            required={false}
            label="Kod polecającego"
            value={credentials.promoCode}
            onChange={(e) =>
              setCredentials({ ...credentials, promoCode: e.target.value })
            }
          />

          <Grid container>
            <Grid item xs>
              {isLoadingCredentials ? (
                <Grid
                  container
                  width={'100%'}
                  marginTop={'10px'}
                  height={'40px'}
                  display={'flex'}
                  justifyContent={'center'}
                >
                  <CircularProgress size={'22px'} sx={{ margin: 'auto' }} />
                </Grid>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handlePasswordSetup}
                  sx={{
                    backgroundColor: colors.primary,
                    mt: 3,
                    height: '40px',
                  }}
                >
                  Kontynuuj
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </AuthenticationBox>
  );
};

export default UserSetupPanel;
