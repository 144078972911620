import React, { useEffect, useState } from 'react';
import 'firebase/auth';
import UserSetupPanel from './UserSetupPanel';
import LoginRegistrationTemplate from 'public-module/components/templates/login-registration-template/LoginRegistrationTemplate';
import UserSetupPanelGoogle from './UserSetupPageGoogle';

const UserSetupPage = () => {
  const [givenEmail, setGivenEmail] = useState<string | null>(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    const emailWithToken = url.searchParams.get('cartId');
    const email = emailWithToken?.split('?')[0];
    console.log('email', email);
    if (email) {
      setGivenEmail(email);
    }
  }, []);

  return (
    <LoginRegistrationTemplate>
      {givenEmail ? (
        <UserSetupPanel email={givenEmail} />
      ) : (
        <UserSetupPanelGoogle email={givenEmail} />
      )}
    </LoginRegistrationTemplate>
  );
};

export default UserSetupPage;
