import { Box, Button, CircularProgress } from '@mui/material';
import { SubscriptionModel } from '../../../models/SubscriptionModel';
import styles from './styles.module.scss';
import { getCssVariable } from 'styles/getVariables';
import { useContext } from 'react';
import { AuthContext } from 'context/Auth';
import moment from 'moment';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useState } from 'react';

type Props = {
  subscriptionModel: SubscriptionModel;
};

const SubscribtionInfo = ({ subscriptionModel }: Props) => {
  const { currentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelSubscription = async () => {
    try {
      setIsLoading(true);
      const functions = getFunctions();
      const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
      await cancelSubscription({ email: subscriptionModel.email });
      // You might want to refresh the page or update the UI here
    } catch (error) {
      console.error('Error canceling subscription:', error);
      // Handle error (show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.tabContainer}></div>
        <div className={styles.card}>
          <p className={styles.planType}>Subskrypcja</p>
          <div className={styles.priceContainer}>
            <span className={styles.price}>250</span>
            <span className={styles.perMonth}>zł/miesiąc</span>
          </div>
          <p className={styles.description}>
            {`Posiadasz ważną subskrypcję dla konta ${subscriptionModel.email}`}
          </p>
          <p
            className={styles.description}
          >{`ostatnia wpłata - ${moment(currentUser?.subscribtionLastPaymentDate).format('DD.MM.YYYY')}`}</p>
          <div className={styles.buttonContainer}>
            {!isLoading ? (
              <Button
                onClick={handleCancelSubscription}
                variant="contained"
                color="primary"
                fullWidth
              >
                Anuluj subskrypcję
              </Button>
            ) : (
              <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <CircularProgress
                  size={30}
                  sx={{ color: getCssVariable('--text-primary') }}
                />
              </Box>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscribtionInfo;
