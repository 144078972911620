import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';
import axios from '../../../../api/axios';
import SendIcon from '@mui/icons-material/Send';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import colors from '../../../../configs/colorConfig';
import { auth, firestore } from '../../../../configs/firebaseConfig';
import InputAdornment from '@mui/material/InputAdornment';
import { doc, getDoc } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import useSidebar from '../../../../hooks/useSidebar';
import { LawModel, lawFromMap } from '../../../../models/LawModel';
import { RulingModel, rulingFromMap } from '../../../../models/RulingModel';
import useTabs from '../../../../hooks/useTabs';
import BotResponse from './components/bot-response/BotResponse';
import { useTranslation } from 'react-i18next';
import {
  getArticlesAsSources,
  getConversationId,
  getRandomRulings,
  getRulingsAsSources,
} from '../../../../api/api-interface';
import debounce from 'lodash/debounce';
import styles from './styles.module.scss';
import { getCssVariable } from 'styles/getVariables';
import useConversotion from 'hooks/useConversation';
import SuggestionQuestions from './components/suggestion-questions/SuggestionQuestions';
import { AuthContext } from 'context/Auth';

type TxtProps = {
  textfieldProps: TextFieldProps;
  onClick: () => void;
  disabled: boolean;
};
const QuestionTextField = ({ textfieldProps, disabled, onClick }: TxtProps) => (
  <TextField
    inputRef={textfieldProps.inputRef}
    value={textfieldProps.value}
    onChange={textfieldProps.onChange}
    sx={{
      background: getCssVariable('--background-color-primary'),
      fontSize: '14px',
    }}
    fullWidth
    multiline
    disabled={disabled}
    size="small"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SendIcon
            sx={{
              color: disabled
                ? getCssVariable('--text-disabled')
                : getCssVariable('--text-primary'),
            }}
            onClick={() => {
              if (!disabled) {
                onClick();
              }
            }}
            style={{ cursor: disabled ? 'default' : 'pointer' }}
          />
        </InputAdornment>
      ),
    }}
    {...textfieldProps}
  >
    {textfieldProps.children}
  </TextField>
);

const QuestionTypography = styled(Typography)<TypographyProps>({
  color: getCssVariable('--text-primary'),
  fontSize: '14px',
});

const UserTypography = styled(Typography)<TypographyProps>({
  color: colors.chat.userTextColor,
  marginRight: '1px',
  fontSize: '14px',
  fontWeight: 'bold',
  display: 'inline-block',
});

const BotTypography = styled(Typography)<TypographyProps>({
  color: colors.chat.userTextColor,
  marginRight: '1px',
  fontSize: '14px',
  fontWeight: 'bold',
  display: 'inline-block',
});

const TypographySub = styled(Typography)<TypographyProps>({
  fontSize: '14px',
  margin: 'auto',
  fontWeight: '500',
  color: getCssVariable('--primary'),
});

type Props = {
  onSourcesChange: (sources: LawModel[], rulings: RulingModel[]) => void;
  onSourcesLoading: (value: boolean) => void;
  focusOnInput: boolean;
  chatId: string | undefined;
  adminMode?: boolean;
};
const ChatFeed = ({
  onSourcesChange,
  onSourcesLoading,
  focusOnInput,
  chatId,
  adminMode,
}: Props) => {
  const { t } = useTranslation();
  const [sourcesKey, setSoucesKey] = useState<string>('-1');
  const [currentResponse, setCurrentResponse] = useState<string>('');
  const [currentQuestion, setCurrentQuestion] = useState<string>('');
  const [listOfQuestions, setListOfQuestions] = useState<string[]>([]);
  const [listOfResponses, setListOfResponses] = useState<string[]>([]);
  const [showHelperQuestions, setShowHelperQuestions] = useState<number>(0);
  const [conversationId, setConverstionId] = useState<string>('');
  const [responseIsRendering, setResponseIsRendering] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { addToast } = useSidebar();
  const { setDisplayedCase, setRulingList } = useTabs();
  const gridRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const [generatingAnswer, setGeneratingAnswer] = useState<boolean>(false);
  const chatStreamResponse = useRef<string>('');
  const { currentUser } = useContext(AuthContext);

  // const findUserData = async () => {
  //   let userData = null;
  //   const querySnapshot = await getDocs(
  //     query(
  //       collection(firestore, "Users"),
  //       where("email", "==", auth.currentUser?.email)
  //     )
  //   );
  //   querySnapshot.forEach((doc) => {
  //     userData = doc.data();
  //     if (userData?.showInstructions == null) {
  //       setShowHelperQuestions(true);
  //     }
  //   });
  // };

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [focusOnInput]);

  useEffect(() => {
    //findUserData();
  }, []);

  // Scrolling logic ==================================================================

  const BOTTOM_SCROLL_MARGIN = 40;

  const [userScrolling, setUserScrolling] = useState(false);
  const [isNearBottom, setIsNearBottom] = useState(true);
  const { setVector } = useConversotion();

  const debouncedHandleScroll = debounce(() => {
    const gridElement = gridRef.current;
    if (gridElement) {
      const { scrollTop, scrollHeight, clientHeight } = gridElement;
      const distanceToBottom = scrollHeight - scrollTop - clientHeight;
      const isNear = distanceToBottom <= BOTTOM_SCROLL_MARGIN;
      setIsNearBottom(isNear);
      setUserScrolling(false);
    }
  }, 100);

  useEffect(() => {
    const gridElement = gridRef.current;
    if (gridElement) {
      gridElement.addEventListener('scroll', debouncedHandleScroll);
      return () => {
        gridElement.removeEventListener('scroll', debouncedHandleScroll);
        debouncedHandleScroll.cancel();
      };
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const gridElement = gridRef.current;
    if (gridElement && !userScrolling && isNearBottom) {
      gridElement.scrollTop = gridElement.scrollHeight;
    }
  }, [currentResponse, userScrolling, isNearBottom]);

  useEffect(() => {
    const gridElement = gridRef.current;
    if (gridElement) {
      gridElement.scrollTop = gridElement.scrollHeight;
    }
  }, [listOfQuestions]);

  const handleManualScroll = () => {
    setUserScrolling(true);
    debouncedHandleScroll();
  };

  useEffect(() => {
    const gridElement = gridRef.current;
    if (gridElement) {
      gridElement.addEventListener('wheel', handleManualScroll);
      return () => {
        gridElement.removeEventListener('wheel', handleManualScroll);
      };
    }
    //eslint-disable-next-line
  }, []);
  // ===============================================================================

  useEffect(() => {
    if (chatId) {
      fetchConversation(chatId);
    } else {
      setListOfQuestions([]);
      setListOfResponses([]);
      onSourcesChange([], []);
      setConverstionId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  const handleClickQuestion = async (index: number) => {
    let newSources = await fetchSourcesForConversationAndKey(
      conversationId,
      index + 1
    );

    onSourcesChange(newSources.laws, newSources.rulings);
  };

  const fetchSourcesForConversationAndKey = async (
    id: string,
    key: number
  ): Promise<{ laws: LawModel[]; rulings: RulingModel[] }> => {
    let userConvo: string[] = [];
    let botConvo: string[] = [];
    let laws: LawModel[] = [];
    const sourceRulings: RulingModel[] = [];
    setConverstionId(id);
    const docRef = doc(firestore, 'Conversations', id);
    let l = await getDoc(docRef).then((doc) => {
      let messages = doc.data()?.messages;
      let sourcesMap = doc.data()?.sources;
      let tabs = doc.data()?.tabs ?? [];
      setRulingList(tabs);
      messages?.forEach((message: any, index: number) => {
        if (index % 2 === 0 && index > 0) {
          botConvo.push(message.content);
        } else if (index % 2 === 1) {
          userConvo.push(message.content);
        }
      });
      let sources: LawModel[] = sourcesMap[key.toString()];

      sources?.forEach((source: any) => {
        if (source.act.nr !== -1 && source.act.year !== -1) {
          let rulings: RulingModel[] = [];

          if (source.rulings != null) {
            source.rulings.forEach((ruling: any) => {
              let r: RulingModel = rulingFromMap(ruling);
              rulings.push(r);
            });
          }

          let law: LawModel = lawFromMap(source.act);
          law.rulings = rulings;
          laws.push(law);
        } else {
          if (source?.rulings) {
            source.rulings.forEach((ruling: any) => {
              sourceRulings.push(rulingFromMap(ruling));
            });
          }
        }
      });
      return { laws: laws, rulings: sourceRulings };
    });
    return l;
  };

  const fetchConversation = async (id: string) => {
    let userConvo: string[] = [];
    let botConvo: string[] = [];
    let laws: LawModel[] = [];
    setConverstionId(id);
    const docRef = doc(firestore, 'Conversations', id);

    try {
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        console.error('No conversation found with ID:', id);
        return;
      }

      const data = docSnap.data();
      const messages = data?.messages || [];
      const vector = data?.synthesis_embedding;
      const sourcesMap = data?.sources || {};
      const tabs = data?.tabs || [];

      setVector(vector || '');
      setRulingList(tabs);
      console.log('sourcesMap0');
      messages.forEach((message: any, index: number) => {
        if (index % 2 === 0 && index > 0) {
          botConvo.push(message.content);
        } else if (index % 2 === 1) {
          userConvo.push(message.content);
        }
      });
      const intKeys = Object.keys(sourcesMap).map(Number);
      const maxKey = intKeys.length > 0 ? Math.max(...intKeys).toString() : '0';
      setSoucesKey(maxKey);
      const sources: LawModel[] = sourcesMap[maxKey] || [];
      const sourceRulings: RulingModel[] = [];
      sources.forEach((source: any) => {
        if (source.act.nr !== -1 && source.act.year !== -1) {
          const rulings: RulingModel[] = [];

          if (source?.rulings) {
            source.rulings.forEach((ruling: any) => {
              rulings.push(rulingFromMap(ruling));
            });
          }

          const law: LawModel = lawFromMap(source.act);
          law.rulings = rulings;
          laws.push(law);
        } else {
          if (source?.rulings) {
            source.rulings.forEach((ruling: any) => {
              sourceRulings.push(rulingFromMap(ruling));
            });
          }
        }
      });

      onSourcesChange(laws, sourceRulings);
      setListOfQuestions(userConvo);
      setListOfResponses(botConvo);
    } catch (error) {
      console.error('Error fetching conversation:', error);
      // Handle error appropriately (e.g., show error message to user)
    }
  };

  const initializeNewQuestion = async (questionText: string) => {
    setDisplayedCase('sources');
    if (!auth.currentUser) return null;

    const token = await auth.currentUser.getIdToken();
    setListOfQuestions((prev) => [...prev, questionText]);
    setResponseIsRendering(true);
    chatStreamResponse.current = '';
    onSourcesLoading(true);
    setIsLoading(true);

    return token;
  };

  const getReplyIdAndNotify = async (token: string, questionText: string) => {
    const replyId = !conversationId
      ? await getConversationId(token, questionText)
      : await getConversationId(token, questionText, conversationId);

    if (!conversationId) {
      addToast({
        message: 'Rozpoczęto nową rozmowę',
        type: 'success',
        id: new Date(),
        title: 'dd',
      });
    }

    return replyId;
  };

  const fetchAndProcessSources = async (token: string, replyId: string) => {
    const randomRulings = await getRandomRulings(replyId, token);
    onSourcesChange([], randomRulings);
    console.log('randomRulings8888888888888', randomRulings);
    const sources = await getArticlesAsSources(token, replyId);
    onSourcesLoading(false);
    console.log('sources888888888888', sources);
    onSourcesChange(sources, randomRulings);

    const pendingRulings = sources.map(async (source, index) => {
      const rulings = await getRulingsAsSources(token, replyId, source);
      sources[index].rulings = rulings;
      sources[index].rulingsLoading = false;
      onSourcesChange([...sources], randomRulings);
      return rulings;
    });

    await Promise.all(pendingRulings);
  };

  const streamResponse = async (token: string, replyId: string) => {
    const baseUrl = axios.defaults.baseURL?.replace(/\/$/, '') || '';
    const url = `${baseUrl}/interface/stream/${replyId}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'text/event-stream',
          'Cache-Control': 'no-cache',
        },
        credentials: 'same-origin',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body!.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';
      let accumulatedText = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const text = decoder.decode(value, { stream: true });
        buffer += text;

        const lines = buffer.split('\n');
        buffer = lines.pop() || '';

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const chunk = line.slice(6); // Remove 'data: ' prefix
            if (chunk === '[DONE]') continue;

            // Parse the literal \n into actual newlines
            const formattedChunk = chunk.replace(/\\n/g, '\n');
            accumulatedText = formattedChunk; // Don't accumulate, just use the latest chunk
            setCurrentResponse((prev) => prev + formattedChunk);
            chatStreamResponse.current = accumulatedText;
          }
        }
      }

      // Handle remaining buffer
      if (buffer.startsWith('data: ')) {
        const chunk = buffer.slice(6);
        if (chunk !== '[DONE]') {
          const formattedChunk = chunk.replace(/\\n/g, '\n');
          accumulatedText = formattedChunk;
          setCurrentResponse((prev) => prev + formattedChunk);
          chatStreamResponse.current = accumulatedText;
        }
      }

      return accumulatedText;
    } catch (error) {
      console.error('Streaming error:', error);
      throw error;
    }
  };

  const handleClick = async (question?: string) => {
    const questionText = question ?? currentQuestion;

    try {
      const token = await initializeNewQuestion(questionText);
      if (!token) {
        throw new Error('Failed to get authentication token');
      }

      const replyId = await getReplyIdAndNotify(token, questionText);
      if (!replyId) {
        throw new Error('Failed to get reply ID');
      }

      await fetchAndProcessSources(token, replyId);

      setGeneratingAnswer(true);
      setIsLoading(false);
      setCurrentResponse('');

      const response = await streamResponse(token, replyId);
      await fetchConversation(replyId);

      setResponseIsRendering(false);
      setCurrentQuestion('');
      setListOfResponses((prev) => [...prev, response]);
      setCurrentResponse('');

      if (listOfResponses.length === 0) {
        navigate(replyId);
      }
    } catch (error) {
      console.error('Error in chat process:', error);
      setIsLoading(false);
      setResponseIsRendering(false);
      setGeneratingAnswer(false);

      addToast({
        message: 'Wystąpił błąd podczas generowania odpowiedzi',
        type: 'error',
        id: new Date(),
        title: 'Błąd',
      });
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleClick();
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentQuestion(e.target.value);
  };

  useEffect(() => {
    setShowHelperQuestions(showHelperQuestions + 1);
    if (location.pathname === '/czat') {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
      setIsLoading(false);
      setCurrentResponse('');
      setResponseIsRendering(false);
      setCurrentQuestion('');
      setGeneratingAnswer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Grid
      container
      pl={1}
      direction="column"
      height={'100%'}
      className="leftSidePanel"
    >
      <Grid
        ref={gridRef}
        item
        xs
        pr={0}
        pt={3}
        style={{ flexGrow: 1, overflowY: 'auto' }}
      >
        {listOfQuestions.map((question, index) => (
          <Grid container key={index} px={2}>
            <Grid item xs={12} py={1}>
              <div
                className={`${styles.chatBubble} ${styles.questionBubble}`}
                onClick={() => handleClickQuestion(index)}
              >
                <Box display={'flex'}>
                  <div className={styles.chatPersonTitle}>
                    <UserTypography>{t('dashboard:chat.you')}</UserTypography>
                  </div>
                </Box>
                <p
                  style={{
                    fontSize: '14px',
                    color: getCssVariable('--text-primary'),
                  }}
                >
                  {question}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} py={1}>
              <div className={`${styles.chatBubble} ${styles.responseBubble}`}>
                <div className={styles.chatPersonTitle}>
                  <BotTypography>{'PrawMi'}</BotTypography>
                </div>
                <>
                  {index === listOfResponses.length ? (
                    <Box>
                      {isLoading ? (
                        <CircularProgress
                          size={'22px'}
                          sx={{ margin: 'auto', marginTop: '10px' }}
                        />
                      ) : (
                        <BotResponse
                          data-testid="response-display"
                          responseText={currentResponse}
                        />
                      )}
                    </Box>
                  ) : (
                    <>
                      <BotResponse responseText={listOfResponses[index]} />
                    </>
                  )}
                </>
              </div>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid item>
        <Grid container>
          {location.pathname === '/czat' &&
            isLoading === false &&
            generatingAnswer === false &&
            showHelperQuestions < 2 &&
            listOfQuestions.length === 0 &&
            (currentUser?.activeTrial || currentUser?.activeSubscription) && (
              <SuggestionQuestions onQuestionClick={handleClick} />
            )}

          {currentUser?.activeTrial === false &&
            currentUser?.activeSubscription === false && (
              <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <Box
                  sx={{
                    p: 2,
                    border: `2px solid ${getCssVariable('--primary')}`,
                    borderRadius: '10px',
                  }}
                >
                  <TypographySub
                    sx={{ color: getCssVariable('--text-primary') }}
                  >
                    Skończył się okres próbny. Nie masz wykupionej subskrypcji.
                  </TypographySub>
                </Box>
              </Box>
            )}

          <Grid item xs={12} display={'flex'} p={3}>
            <div className={'questionDiv'} style={{ width: '100%' }}>
              {adminMode ? (
                <></>
              ) : (
                <QuestionTextField
                  data-testid="ask-question-button"
                  textfieldProps={{
                    value: !responseIsRendering ? currentQuestion : '',
                    inputRef: textFieldRef,
                    onKeyDown: (e) => handleKeyPress(e),
                    onChange: handleOnChange,
                  }}
                  disabled={
                    responseIsRendering ||
                    (currentUser?.activeTrial === false &&
                      currentUser?.activeSubscription === false)
                  }
                  onClick={handleClick}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChatFeed;
