import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import alerts from './alerts.json';

interface AlertModel {
  title: string;
  startDate: Date;
  endDate: Date;
}

const AlertNotificator = () => {
  const [activeAlert, setActiveAlert] = useState<AlertModel | undefined>();

  useEffect(() => {
    const closedAlert = localStorage.getItem('closedAlert');
    if (closedAlert) return;

    const activeAlert = alerts
      .map((alert) => ({
        title: alert['title'],
        startDate: new Date(alert['startDate']),
        endDate: new Date(alert['endDate']),
      }))
      .find((alert) => {
        const startDate = new Date(alert['startDate']);
        const endDate = new Date(alert['endDate']);
        const currentDate = new Date();

        return currentDate >= startDate && currentDate <= endDate;
      });

    console.log('activeAlert');
    console.log(activeAlert);
    setActiveAlert(activeAlert);
  }, []);

  const handleClose = () => {
    if (activeAlert) {
      localStorage.setItem('closedAlert', JSON.stringify(activeAlert));
    }
    setActiveAlert(undefined);
  };

  if (!activeAlert) return null;

  return (
    <Box
      position={'absolute'}
      sx={{ top: '0', width: 'calc(100% - 100px)' }}
      display={'flex'}
      zIndex={10000}
      justifyContent={'center'}
    >
      <Box
        sx={{ background: '#FDE992', borderRadius: '5px' }}
        display={'flex'}
        alignItems={'center'}
        px={'10px'}
        gap={'10px'}
        maxWidth={'80%'}
      >
        <p style={{ color: '#000' }}>{activeAlert.title}</p>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
    </Box>
  );
};

export default AlertNotificator;
