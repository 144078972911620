import { Box, Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { RulingsSerachFilter } from '../../../models/RulingsSerachFilter';
import {
  administrativeCourts,
  commonCourts,
  supremeCourts,
} from '../search-tab-panel/courts';
import TextFieldWithPlaceholder from '../../atoms/textfield-with-placeholder/TextFieldWithPlaceholder';

type Props = {
  onSearch: (filters: RulingsSerachFilter) => void;
};

const SearchRulingFilters = ({ onSearch }: Props) => {
  const [filters, setFilters] = useState<RulingsSerachFilter>({
    name: '',
    courtType: 'ALL',
    judgeName: '',
  });
  const [listOfCourts, setListOfCourts] = useState<string[]>([]);

  useEffect(() => {
    if (filters.courtType === 'ALL') {
      setListOfCourts([]);
    } else if (filters.courtType === 'COMMON') {
      setListOfCourts(commonCourts);
    } else if (filters.courtType === 'ADMINISTRATIVE') {
      setListOfCourts(administrativeCourts);
    } else if (filters.courtType === 'SUPREME') {
      setListOfCourts(supremeCourts);
    }
  }, [filters.courtType]);

  type CourtType = 'ALL' | 'COMMON' | 'ADMINISTRATIVE' | 'SUPREME';

  const labels: Record<CourtType, string> = {
    ALL: 'Wszystkie',
    COMMON: 'Powszechny',
    ADMINISTRATIVE: 'Administracyjny',
    SUPREME: 'Sąd Najwyższy',
  };

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      onSearch(filters);
    }
  };

  return (
    <Box maxWidth={'100%'}>
      <Grid container spacing={2}>
        <Grid item md={12} lg={12} display={'flex'}>
          <Box maxWidth={'500px'} width={'100%'}>
            <Typography fontSize={'12px'}>Sygnatura / Zapytanie semantyczne</Typography>
            <TextFieldWithPlaceholder
              value={filters.name}
              onKeyDown={handleKeyPress}
              onChange={(e) => setFilters({ ...filters, name: e.target.value })}
              placeholderNode={
                <div>
                  np.: <i>"I ACa 772/13"</i>, lub <i>"nadużywanie pozycji dominującej"</i>
                </div>
              }
            />
          </Box>
          <Button
            variant="contained"
            sx={{ ml: '10px', mt: '18px', textTransform: 'none' }}
            onClick={() => {
              onSearch(filters);
            }}
          >
            Szukaj
          </Button>
        </Grid>

        {/* <Grid item md={12} lg={6}>
          <Typography fontSize={"12px"}>Sędzia</Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            placeholder="Imię i nazwisko"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={12} lg={6}>
          <Typography fontSize={"12px"}>Rodzaj sadu</Typography>
          <Select
            size="small"
            fullWidth
            onChange={(e) =>
              setFilters({
                ...filters,
                courtType: e.target.value as CourtType,
                courtName: "",
              })
            }
            value={filters.courtType}
            renderValue={(selected: CourtType) => (
              <span
                style={{
                  fontStyle: selected === "ALL" ? "italic" : "normal",
                  color: selected === "ALL" ? "#888" : "black",
                }}
              >
                {labels[selected]}
              </span>
            )}
          >
            <MenuItem value={"ALL"} sx={{ fontStyle: "italic", color: "#888" }}>
              Wszystkie
            </MenuItem>
            <MenuItem value={"COMMON"}>Powszechny</MenuItem>
            <MenuItem value={"ADMINISTRATIVE"}>Administracyjny</MenuItem>
            <MenuItem value={"SUPREME"}>Sąd Najwyższy</MenuItem>
          </Select>
        </Grid>
        <Grid item md={12} lg={6}>
          <Typography
            fontSize={"12px"}
            color={filters.courtType === "ALL" ? "lightgray" : ""}
          >
            Nazwa sądu
          </Typography>
          <Autocomplete
            key={filters.courtType}
            options={listOfCourts}
            value={filters.courtName}
            onChange={(e, value) =>
              setFilters({
                ...filters,
                courtName: value ?? "",
              })
            }
            disabled={filters.courtType === "ALL"}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                placeholder="Wybierz sąd"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item md={12} lg={6}>
          <Typography fontSize={"12px"}>Zakres dat</Typography>
          <Box
            display={"flex"}
            maxWidth={"600px"}
            justifyContent={"space-between"}
          >
            <Calendar />
            <Calendar />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          marginTop={"30px"}
        >
          <Button
            variant="contained"
            sx={{ ml: "10px", textTransform: "none" }}
            onClick={() => {
              onSearch(filters);
            }}
          >
            Szukaj
          </Button>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default SearchRulingFilters;
