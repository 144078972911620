import { Box, Button, CircularProgress, Typography } from '@mui/material';
import useTabs from 'hooks/useTabs';
import { RulingModel } from 'models/RulingModel';
import { useParams } from 'react-router-dom';

type Props = {
  rulingsList: RulingModel[];
  loading: boolean;
};

const RulingsList = ({ rulingsList, loading }: Props) => {
  const { id } = useParams();
  const { addRuling, setDisplayedCase } = useTabs();

  const renderBox = () => {
    if (loading === true) {
      return (
        <Box
          height={'300px'}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <CircularProgress />
        </Box>
      );
    } else if (rulingsList.length === 0) {
      return (
        <Box
          height={'300px'}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography fontStyle={'italic'} fontSize={'16px'}>
            Brak orzeczeń powiązanych z danym fragmentem
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box>
          {rulingsList.map((ordinance, index) => {
            return (
              <Box
                key={index}
                sx={{ borderBottom: '1px solid lightgray', padding: '10px' }}
              >
                <Button
                  sx={{ textTransform: 'none' }}
                  onClick={() => {
                    // onClick(ordinance);

                    addRuling(ordinance, id);
                    setDisplayedCase(ordinance);
                  }}
                >
                  {ordinance.title}
                </Button>
                <Typography fontSize={'14px'}>{ordinance.text}</Typography>
              </Box>
            );
          })}
        </Box>
      );
    }
  };

  return <>{renderBox()}</>;
};

export default RulingsList;
