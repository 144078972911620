import { Box, Button, Grid, Popover, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import colors from '../../../configs/colorConfig';
import { useContext, useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../../../configs/firebaseConfig';
import { useNavigate } from 'react-router';
import React from 'react';
import { getCssVariable } from 'styles/getVariables';
import { AuthContext } from 'context/Auth';

type Props = {
  extended: boolean;
};
const LoginControls = ({ extended }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const navigate = useNavigate();
  const { currentUser, userRole } = useContext(AuthContext);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const handlePreferences = () => {
    navigate('/ustawienia');
  };

  const handleSubscription = () => {
    navigate('/subskrypcja');
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper': {
            background: getCssVariable('--background-color-secondary'),
          },
        }}
      >
        <Box sx={{ width: '200px' }}>
          <Typography
            sx={{
              p: 1,
              textAlign: 'center',
              color: getCssVariable('--text-primary'),
            }}
          >
            {currentUser?.firstName || currentUser?.lastName
              ? `${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`.trim()
              : currentUser?.email || ''}
          </Typography>
          <hr style={{ margin: '0px 0', border: '1px solid #ccc' }} />
          <Button
            sx={{ color: getCssVariable('--text-primary') }}
            fullWidth
            onClick={handlePreferences}
          >
            Preferencje
          </Button>
          <Button
            sx={{ color: getCssVariable('--text-primary') }}
            fullWidth
            onClick={handleSubscription}
          >
            Konto
          </Button>
          {userRole === 1134 && (
            <Button
              sx={{ color: getCssVariable('--text-primary') }}
              fullWidth
              onClick={() => {
                navigate('/mnb');
              }}
            >
              Admin
            </Button>
          )}
          <Button
            sx={{ color: getCssVariable('--text-primary') }}
            fullWidth
            onClick={handleSignOut}
          >
            Wyloguj
          </Button>
        </Box>
      </Popover>
      <Grid
        display="flex"
        component={'button'}
        sx={{
          background: getCssVariable('--navbar-color'),
          border: 'none',
          ':hover': {
            background: getCssVariable('--navbar-color'),
          },
        }}
        onClick={handleClick}
      >
        <AccountCircleIcon sx={{ color: colors.sidebarLeft.text }} />
        {extended ? (
          <Typography ml={1} color={colors.sidebarLeft.text}>
            {auth.currentUser?.displayName ?? ''}
          </Typography>
        ) : null}
      </Grid>
    </>
  );
};

export default LoginControls;
