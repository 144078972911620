import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  getFreeTrialEndDate,
  getPremiumStatus,
  SubscriptionDetails,
} from './components/GetPremiumStatus';
import { auth } from '../../../configs/firebaseConfig';
import { SubscriptionModel } from '../../models/SubscriptionModel';
import SubscribtionInfo from '../../components/organisms/subscription-info/SubscriptionInfo';
import SubscribtionPaymentPanel from 'settings-module/components/organisms/subscription-panel/SubscribtionPaymentPanel';
import { Close } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { getCssVariable } from 'styles/getVariables';

const isSubscriptionActive = (endDate?: Date): boolean => {
  if (!endDate) return false;
  return endDate > new Date();
};

const SubscriptionPage = () => {
  const { text } = useParams();
  const [open, setOpen] = useState<boolean>(true);
  const [subscriptionModel, setSubscriptionModel] = useState<SubscriptionModel>(
    {
      subscribtionPayed: undefined,
      subscribtionCanceled: false,
      email: auth.currentUser?.email ?? '',
      displayName: auth.currentUser?.displayName ?? undefined,
    }
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [trialEndDate, setTrialEndDate] = useState<Date | undefined>(undefined);
  const navigate = useNavigate();

  const fetchSubscriptionInfo = async () => {
    setIsLoading(true);
    const subed: SubscriptionDetails | null = await getPremiumStatus();
    const trial = await getFreeTrialEndDate();
    setTrialEndDate(trial);

    if (!subed) {
      setSubscriptionModel((prev) => ({
        ...prev,
        subscribtionPayed: false,
        subscribtionCanceled: false,
      }));
    } else {
      const subscription = subed as SubscriptionDetails;
      setSubscriptionModel((prev) => ({
        ...prev,
        subscribtionPayed: isSubscriptionActive(
          subscription.current_period_end
        ),
        subscribtionCanceled: subscription.cancel_at_period_end ?? false,
        currentPeridStart: subscription.current_period_start,
        currentPeridEnd: subscription.current_period_end,
        createdAt: subscription.created,
      }));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSubscriptionInfo();
  }, []);

  return (
    <>
      <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
        <IconButton
          onClick={() => {
            navigate('/czat');
          }}
          sx={{ color: getCssVariable('--text-primary') }}
        >
          <Close sx={{ fontSize: '28px' }} />
        </IconButton>
      </div>
      <Grid
        container
        display={'flex'}
        width={'100%'}
        height={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Grid>
          {isLoading ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {subscriptionModel.subscribtionPayed &&
              subscriptionModel.subscribtionCanceled === false ? (
                <SubscribtionInfo subscriptionModel={subscriptionModel} />
              ) : (
                <SubscribtionPaymentPanel
                  subscribtionCanceled={subscriptionModel.subscribtionCanceled}
                  currentPeriodEnd={subscriptionModel.currentPeridEnd}
                  email={auth.currentUser?.email ?? ''}
                  trialEndDate={trialEndDate}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Modal
        open={!!text && text !== 'true' && open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 700,
            bgcolor: getCssVariable('--background-color-primary'),
            border: '1px solid #ddd',
            borderRadius: '10px',
            boxShadow: 24,
            p: 3,
          }}
        >
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              paddingBottom: '20px',
            }}
          >
            {'Dziękujemy za subskrypcję!'}
          </p>
          <p
            style={{
              paddingBottom: '20px',
            }}
          >
            {
              'Twoja subskrypcja została pomyślnie aktywowana. Cieszymy się, że jesteś z nami! Jeśli masz pytania, śmiało skontaktuj się z naszym zespołem wsparcia.'
            }
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" onClick={() => setOpen(false)}>
              Zamknij
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default SubscriptionPage;
