import { Link, LinkProps, styled } from "@mui/material";
import React from "react";
import colors from "../../../../configs/colorConfig";
// import { HashLink } from 'react-router-hash-link';
import { auth } from "../../../../configs/firebaseConfig";
import { useLocation } from "react-router-dom";

const StyledLink = styled(Link)<LinkProps>({
  color: "gray",
  textDecoration: "none",
  ":&hover": {
    color: "blue-900",
  },
});

// const StyledButton = styled(Button)<ButtonProps>({
//     textDecoration: "none",
//     textTransform: "none",
//     borderRadius: "0.75rem",
//     fontSize: "1rem",
//     backgroundColor: "white",
//     '&:hover': {
//         backgroundColor: "white",
//     },
// })

const NavLinks = () => {
  const location = useLocation();

  const isBlogPage = location.pathname.substring(0, 5) === "/blog";

  return (
    <>
      {!isBlogPage && (
        <>
          <StyledLink
            style={{ color: "lightGray" }}
            className="px-4 font-extrabold text-gray-900 hover:text-blue-900"
            href="#narzedzia-h4"
          >
            Narzędzia
          </StyledLink>
          <StyledLink
            style={{ color: "lightGray" }}
            className="px-4 font-extrabold text-gray-600 hover:text-blue-900"
            href="#kontakt"
          >
            Kontakt
          </StyledLink>
          <StyledLink
            style={{ color: "lightGray", border: "1px solid lightGray" }}
            className="inline-flex items-center justify-center w-auto px-6 py-2 shadow-xl rounded-xl"
            href="blog"
          >
            Blog
          </StyledLink>
        </>
      )}

      {auth.currentUser ? (
        <>
          {/* <StyledButton
              sx={{ color: colors.primary }}
              onClick={() => {
                navigete("/czat");
              }}
            >
              Czat
            </StyledButton> */}
          <StyledLink
            sx={{ color: colors.primary, background: "white" }}
            className="inline-flex items-center justify-center w-auto px-6 py-2 shadow-xl rounded-xl"
            href="czat"
          >
            Czat
          </StyledLink>
        </>
      ) : (
        <>
          <StyledLink
            sx={{ color: "lightGray", border: "1px solid lightGray" }}
            className="inline-flex items-center justify-center w-auto px-6 py-2 shadow-xl rounded-xl"
            href="logowanie"
          >
            Logowanie
          </StyledLink>
          <StyledLink
            sx={{ color: colors.primary, background: "white" }}
            className="inline-flex items-center justify-center w-auto px-6 py-2 shadow-xl rounded-xl"
            href="rejestracja"
          >
            Wypróbuj demo
          </StyledLink>
        </>
      )}
    </>
  );
};

export default NavLinks;
