import styles from "./styles.module.scss";

const StatutePage = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {" "}
      <div className={styles.policyDiv}>
        <h2>REGULAMIN ŚWIADCZENIA USŁUGI PrawMi</h2>
        <div className={styles.statuteContainer}>
          <h4 className={styles.titleH4}>§1 Przedmiot Regulaminu</h4>

          <ol>
            <li>
              Niniejszy Regulamin określa zasady i warunki świadczenia usług
              drogą elektroniczną przez Prawmi PSA z siedzibą w Warszawie, ul.
              Orla 6/37, 00-143 Warszawa, wpisaną do Krajowego Rejestru
              Przedsiębiorców przez Sąd Rejonowy w Warszawie, XII Wydział
              Gospodarczy KRS pod nr 0001131953 (zwaną dalej "Dostawcą"), w
              ramach oprogramowania o nazwie „PrawMi” (zwanego dalej
              „Aplikacją"), zgodnie z Ustawą z dnia 18 lipca 2002 r. o
              świadczeniu usług drogą elektroniczną (Dz.U. 2020 poz. 344).
            </li>
            <li>
              Korzystanie z Aplikacji i świadczonych za jej pośrednictwem Usług
              wymaga uprzedniego zapoznania się z treścią Regulaminu i jego
              akceptacji. Brak akceptacji Regulaminu uniemożliwia korzystanie z
              Usług.
            </li>
            <li>
              Umowa zawierana między Dostawcą a Klientem na podstawie
              niniejszego Regulaminu jest umową o świadczenie usług drogą
              elektroniczną.
            </li>
            <li>
              Usługa jest świadczona wyłącznie dla Klientów będących
              przedsiębiorcami i profesjonalnymi prawnikami - nie jest dostępna
              dla konsumentów.
            </li>
          </ol>

          <h4 className={styles.titleH4}>§2 Definicje</h4>
          <ol>
            Ilekroć w Regulaminie jest mowa o:
            <li>
              Kliencie - należy przez to rozumieć osobę prawną, jednostkę
              organizacyjną nieposiadającą osobowości prawnej lub pełnoletnią
              osobę fizyczną korzystającą z Aplikacji w celu związanym
              bezpośrednio z jej działalnością gospodarczą lub zawodową;
            </li>
            <li>
              Aplikacji - należy przez to rozumieć oprogramowanie PrawMi oparte
              na technologii sztucznej inteligencji, dostępne pod adresem
              prawmi.pl;
            </li>
            <li>
              Cenniku - należy przez to rozumieć dostępny w ramach Aplikacji
              wykaz cen obowiązujących za umożliwienie korzystania z Usług;
            </li>
            <li>
              Koncie Klienta - należy przez to rozumieć indywidualny dostęp do
              Aplikacji przypisany do Klienta, za pośrednictwem którego Klient
              może korzystać z Usług;
            </li>
            <li>
              Usługach - należy przez to rozumieć funkcjonalności dostępne w
              ramach Aplikacji, szczegółowo opisane w §7 niniejszego Regulaminu;
            </li>
            <li>
              Treściach Generowanych - należy przez to rozumieć teksty, analizy
              i inne materiały tworzone przez Aplikację w oparciu o technologię
              AI (Sztucznej Inteligencji);
            </li>
            <li>
              Okresie rozliczeniowym - należy przez to rozumieć okres, za który
              dokonywane są rozliczenia płatności Klienta z tytułu Usług
              świadczonych w trybie abonamentowym, rozpoczynający się w dniu
              dokonania płatności i kończący się miesiąc (lub inną liczbę
              miesięcy, w zależności od wybranej z Cennika oferty) później w
              dniu odpowiadającym początkowi tego okresu;
            </li>
            <li>
              Opłacie abonamentowej - należy przez to rozumieć opłatę uiszczaną
              przez Klienta, w wysokości określonej w Cenniku, tytułem
              korzystania z Usług w danym Okresie rozliczeniowym.
            </li>
          </ol>

          <h4 className={styles.titleH4}>§3 Charakter prawny Aplikacji</h4>
          <ol>
            <li>
              Aplikacja PrawMi jest narzędziem opartym na technologii sztucznej
              inteligencji, służącym do wspomagania pracy prawników i osób
              zainteresowanych zagadnieniami prawnymi.
            </li>
            <li>
              Informacje i analizy generowane przez Aplikację nie stanowią porad
              prawnych w rozumieniu przepisów prawa i nie mogą być traktowane
              jako substytut profesjonalnej pomocy prawnej.
            </li>
            <li>
              Dostawca nie świadczy usług prawnych w rozumieniu obowiązujących
              przepisów prawa, w tym w szczególności w rozumieniu Rozporządzenia
              (WE) nr 184/2005 Parlamentu Europejskiego i Rady z dnia 12
              stycznia 2005 r. w sprawie statystyki Wspólnoty w zakresie bilansu
              płatniczeg
            </li>
            <li>
              Korzystanie z Aplikacji nie tworzy stosunku prawnego między
              Klientem a Dostawcą, który mógłby być interpretowany jako
              świadczenie pomocy prawnej.
            </li>
          </ol>
          <h4 className={styles.titleH4}>§4 Wymagania techniczne</h4>
          <ol>
            <li>
              Korzystanie z Aplikacji wymaga spełnienia następujących
              minimalnych wymagań technicznych: a) urządzenie z dostępem do
              Internetu, b) przeglądarka internetowa w aktualnej wersji
              (zalecane: Google Chrome, Mozilla Firefox, Safari, Microsoft
              Edge), c) włączona obsługa plików cookies i JavaScript, d) aktywne
              konto poczty elektronicznej, e) minimalna rozdzielczość ekranu:
              1024x768 pikseli.
            </li>
            <li>
              Dostawca nie ponosi odpowiedzialności za problemy techniczne bądź
              ograniczenia techniczne występujące w sprzęcie komputerowym, z
              którego korzysta Klient, a które uniemożliwiają Klientowi
              korzystanie z Aplikacji.
            </li>
          </ol>
          <h4 className={styles.titleH4}>§5 Rejestracja</h4>
          <ol>
            <li>Korzystanie z Aplikacji wymaga utworzenia Konta Klienta.</li>
            <li>
              W celu utworzenia Konta Klienta, należy wypełnić formularz
              rejestracyjny dostępny w Aplikacji, podając m.in. następujące dane
              (w zależności od okoliczności): a) adres e-mail, b) numer
              telefonu, c) dane identyfikacyjne (imię, nazwisko, nazwa firmy),
              d) numer NIP.
            </li>
            <li>
              Klient zobowiązany jest do podania prawdziwych i aktualnych
              danych.
            </li>
            <li>
              W celu utworzenia Konta Klienta niezbędna jest akceptacja
              postanowień Regulaminu.
            </li>
            <li>
              Po wypełnieniu formularza rejestracyjnego, na podany adres e-mail
              zostanie wysłana wiadomość z linkiem aktywacyjnym. Kliknięcie w
              link aktywacyjny kończy proces rejestracji i pozwala na pierwsze
              logowanie do Aplikacji.
            </li>
            <li>
              Klient zobowiązuje się do nieudostępniania danych logowania do
              swojego Konta Klienta osobom trzecim.
            </li>
            <li>
              Klient może mieć możliwość rejestracji i logowania do Aplikacji za
              pomocą swojego konta Google lub innych podobnych narzędzi.
            </li>
            <li>
              Korzystając ze wskazanej w ust. powyżej opcji, Klient upoważnia
              Aplikację do uzyskania dostępu do informacji podstawowych o
              koncie, takich jak imię, nazwisko, adres e-mail oraz zdjęcie
              profilowe, w celu utworzenia i zarządzania kontem w Aplikacji.
            </li>
            <li>
              Użytkownik zobowiązuje się do przestrzegania zasad bezpieczeństwa
              swojego konta Google lub innych podobnych narzędzi oraz ponosi
              pełną odpowiedzialność za działania związane z jego kontem w
              Aplikacji.
            </li>
          </ol>
          <h4 className={styles.titleH4}>
            §6 Zawarcie i rozwiązanie umowy z Klientem
          </h4>
          <ol>
            <li>
              Umowa o świadczenie Usług zostaje zawarta z chwilą aktywacji Konta
              Klienta i obowiązuje na czas nieokreślony.
            </li>
            <li>
              Zarówno Klient, jak i Dostawca mogą rozwiązać umowę w dowolnej
              chwili.
            </li>
            <li>
              W przypadku usług płatnych, umowa ulega rozwiązaniu wraz z końcem
              ostatniego opłaconego Okresu rozliczeniowego.
            </li>
            <li>
              W przypadku usług bezpłatnych, umowa ulega rozwiązaniu w momencie
              usunięcia Konta Klienta.
            </li>
            <li>
              Klient może wypowiedzieć umowę poprzez usunięcie Konta Klienta w
              Aplikacji lub przesłanie stosownego oświadczenia na adres e-mail
              Dostawcy, a także poprzez zaniechanie opłacania korzystania z
              Aplikacji.
            </li>
            <li>
              Dostawca może wypowiedzieć umowę ze skutkiem natychmiastowym w
              przypadku: a) naruszenia przez Klienta postanowień Regulaminu, b)
              naruszenia przez Klienta obowiązujących przepisów prawa, c)
              podania przez Klienta nieprawdziwych danych, d) wykorzystywania
              Aplikacji do celów niezgodnych z prawem lub dobrymi obyczajami, e)
              innych uzasadnionych przypadków, w szczególności, gdy działania
              Klienta zagrażają bezpieczeństwu Aplikacji lub innych Klientów.
            </li>
            <li>
              Dostawca może również wypowiedzieć umowę z miesięcznym terminem
              wypowiedzenia w przypadku zakończenia świadczenia Usług.
            </li>
            <li>
              Po rozwiązaniu umowy Konto Klienta zostaje usunięte, a dostęp do
              Usług zablokowany. W każdym przypadku Dostawca umożliwi Klientowi
              dostęp do kopii jego danych przechowywanych w Aplikacji w terminie
              30 dni od zakończenia obowiązywania umowy.
            </li>
          </ol>
          <h4 className={styles.titleH4}>§7 Zakres Usług</h4>
          <ol>
            <li>
              W ramach Aplikacji Dostawca udostępnia Klientom następujące
              Usługi: a) generowanie dokumentów prawnych w oparciu o dane
              wprowadzone przez Klienta, b) analiza przepisów prawnych i
              orzecznictwa, c) wyszukiwanie i zadawanie pytań do orzeczeń,
              kodeksów i innych ogólnodostępnych dokumentów prawnych, d) rozmowa
              z czatbotem prawnym opartym na AI, e) automatyczne generowanie
              podsumowań i streszczeń dokumentów prawnych, f) inne
              funkcjonalności opisane w Aplikacji.
            </li>
            <li>
              Dostawca zastrzega sobie prawo do modyfikacji zakresu świadczonych
              Usług, w tym do wprowadzania nowych funkcjonalności lub usuwania
              istniejących.
            </li>
            <li>
              Korzystanie z niektórych Usług może wymagać uiszczenia dodatkowej
              opłaty zgodnie z aktualnym Cennikiem.
            </li>
          </ol>
          <h4 className={styles.titleH4}>§8 Odpłatność Usług</h4>
          <ol>
            <li>
              Korzystanie z podstawowych funkcjonalności Aplikacji jest
              bezpłatne przez okres próbny wynoszący 14 dni od momentu
              utworzenia Konta Klienta, chyba że co innego zostało ustalone
              pomiędzy stronami umowy.
            </li>
            <li>
              Po zakończeniu okresu próbnego korzystanie z Aplikacji jest
              odpłatne zgodnie z aktualnym Cennikiem dostępnym w Aplikacji.
            </li>
            <li>
              Opłaty abonamentowe uiszczane są z góry za wybrany Okres
              rozliczeniowy.
            </li>
            <li>
              Brak terminowej płatności może skutkować zablokowaniem dostępu do
              Aplikacji.
            </li>
            <li>
              Dostawca zastrzega sobie prawo do zmiany Cennika, przy czym zmiany
              te nie dotyczą opłat już uiszczonych za bieżący Okres
              rozliczeniowy.
            </li>
            <li>Wniesione opłaty nie podlegają zwrotowi.</li>
          </ol>
          <h4 className={styles.titleH4}>§9 Płatności</h4>
          <ol>
            <li>
              Dostawca umożliwia dokonywanie płatności za Usługi w następujących
              formach: a) przelew bankowy, b) płatność kartą kredytową, c)
              płatności elektroniczne (np. PayPal, BLIK).
            </li>
            <li>
              Za moment dokonania płatności uznaje się moment zaksięgowania
              środków na rachunku bankowym Dostawcy.
            </li>
            <li>
              Dostawca wystawia faktury VAT za świadczone Usługi. Faktury są
              dostarczane Klientowi w formie elektronicznej na adres e-mail
              podany podczas rejestracji.
            </li>
            <li>
              Klient wyraża zgodę na otrzymywanie faktur drogą elektroniczną.
            </li>
          </ol>
          <h4 className={styles.titleH4}>§10 Prawa i obowiązki Klienta</h4>
          <ol>
            <li>
              Klient zobowiązuje się do korzystania z Aplikacji zgodnie z jej
              przeznaczeniem, postanowieniami Regulaminu oraz obowiązującymi
              przepisami prawa.
            </li>
            <li>
              Klient nie może udostępniać swojego Konta Klienta osobom trzecim.
            </li>
            <li>
              Klient ponosi odpowiedzialność za treści wprowadzane przez niego
              do Aplikacji oraz za to, by w treściach tych nie znajdowały się
              informacje poufne.
            </li>
            <li>
              Klient zobowiązuje się do niepodejmowania działań, które mogłyby
              zakłócić prawidłowe funkcjonowanie Aplikacji, w tym do
              nieumieszczania treści o charakterze bezprawnym.
            </li>
            <li>
              Klient ma prawo do zgłaszania Dostawcy wszelkich nieprawidłowości
              w działaniu Aplikacji.
            </li>
          </ol>
          <h4 className={styles.titleH4}>§11 Prawa i obowiązki Dostawcy</h4>
          <ol>
            <li>
              Dostawca dołoży starań by zapewnić ciągłości działania Aplikacji.
              Dostawca rezerwuje sobie prawo do przerw technicznych w działaniu
              Aplikacji, ogłaszanych z co najmniej 5-godzinnym wyprzedzeniem.
            </li>
            <li>
              Dostawca ma prawo do czasowego ograniczenia dostępu do Aplikacji w
              celu przeprowadzenia niezbędnych prac konserwacyjnych lub
              aktualizacji.
            </li>
            <li>
              Dostawca nie ponosi odpowiedzialności za przerwy w dostępie do
              Aplikacji wynikające z przyczyn od niego niezależnych, w tym
              awarii łączy telekomunikacyjnych lub sprzętu Klienta.
            </li>
            <li>
              Dostawca zastrzega sobie prawo do modyfikacji funkcjonalności
              Aplikacji, w tym do wprowadzania nowych usług lub usuwania
              istniejących.
            </li>
          </ol>
          <h4 className={styles.titleH4}>§12 Wsparcie techniczne</h4>
          <ol>
            <li>
              Dostawca zapewnia wsparcie techniczne dla Klientów w zakresie
              korzystania z Aplikacji.
            </li>
            <li>
              Wsparcie techniczne jest dostępne w dni robocze w godzinach
              9:00-17:00 poprzez: a) formularz kontaktowy dostępny w Aplikacji,
              b) adres e-mail: support@prawmi.pl,.
            </li>
            <li>
              Czas odpowiedzi na zgłoszenia wsparcia technicznego wynosi do 24
              godzin roboczych.
            </li>
            <li>
              Wsparcie techniczne nie obejmuje porad prawnych ani merytorycznej
              pomocy w korzystaniu z treści generowanych przez Aplikację.
            </li>
          </ol>
          <h4 className={styles.titleH4}>§13 Prawa autorskie</h4>
          <ol>
            <li>
              Wszelkie prawa własności intelektualnej do Aplikacji, w tym do jej
              kodu źródłowego, interfejsu graficznego oraz treści
              niedostarczonych przez Klientów, należą do Dostawcy lub podmiotów,
              z którymi Dostawca zawarł stosowne umowy.
            </li>
            <li>Korzystanie z Aplikacji nie oznacza nabycia przez Klienta</li>
            <li>
              Nazwa PrawMi, logo oraz inne oznaczenia słowne i graficzne używane
              w związku z Aplikacją stanowią chronione prawem dobra Dostawcy.
              Ich wykorzystywanie bez zgody Dostawcy jest zabronione.
            </li>
          </ol>
          <h4 className={styles.titleH4}>
            §14 Licencja na korzystanie z Treści Generowanych
          </h4>
          <ol>
            <li>
              Dostawca udziela Klientowi niewyłącznej, przenoszalnej licencji,
              bez ograniczeń terytorialnych i czasowych, na korzystanie z Treści
              Generowanych przez Aplikację.
            </li>
            <li>
              Licencja obejmuje prawo do: a) trwałego lub czasowego
              zwielokrotniania Treści Generowanych w całości lub w części,
              jakimikolwiek środkami i w jakiejkolwiek formie; b) tłumaczenia,
              przystosowywania, zmiany układu lub jakichkolwiek innych zmian w
              Treściach Generowanych; c) rozpowszechniania, w tym użyczenia lub
              najmu, Treści Generowanych lub ich kopii; d) publicznego
              wykonywania, wystawiania, wyświetlania, odtwarzania oraz nadawania
              i reemitowania, a także publicznego udostępniania Treści
              Generowanych w taki sposób, aby każdy mógł mieć do nich dostęp w
              miejscu i w czasie przez siebie wybranym.
            </li>
            <li>
              Klient ma prawo do swobodnego wykorzystywania Treści Generowanych
              w ramach prowadzonej działalności gospodarczej lub zawodowej, w
              tym do celów komercyjnych, bez konieczności uiszczania dodatkowych
              opłat na rzecz Dostawcy.
            </li>
            <li>
              Klient jest uprawniony do: a) włączania Treści Generowanych do
              własnych utworów; b) modyfikowania i adaptacji Treści Generowanych
              według własnych potrzeb; c) udzielania sublicencji na Treści
              Generowane swoim klientom lub partnerom biznesowym w zakresie
              niezbędnym do realizacji usług świadczonych przez Klienta.
            </li>
            <li>
              Licencja pozostaje w mocy także po rozwiązaniu lub wygaśnięciu
              umowy o świadczenie Usług.
            </li>
            <li>
              Dostawca zastrzega sobie prawo do wykorzystywania Treści
              Generowanych, jak również treści wprowadzanych przez Klientów, w
              celu ulepszania Aplikacji i jej funkcjonalności.
            </li>
          </ol>

          <h4 className={styles.titleH4}>§15 Odpowiedzialność Dostawcy</h4>
          <ol>
            <li>
              Całkowita odpowiedzialność Dostawcy wobec Klienta lub wszelkich
              innych osób trzecich, jest ograniczona do strat rzeczywistych oraz
              do wysokości opłat uiszczonych przez Klienta za korzystanie z
              Aplikacji w okresie 3 miesięcy poprzedzających wystąpienie szkody.
            </li>
            <li>
              Dostawca nie ponosi odpowiedzialności za utratę danych Klienta
              wprowadzonych do Aplikacji.
            </li>
            <li>
              Dostawca nie ponosi odpowiedzialności za szkody powstałe w wyniku
              nieprawidłowego działania sprzętu lub oprogramowania Klienta, a
              także za szkody w postaci utraconych korzyści.
            </li>
          </ol>

          <h4 className={styles.titleH4}>
            §16 Wyłączenie odpowiedzialności za skutki korzystania z Aplikacji
          </h4>
          <ol>
            <li>
              Klient przyjmuje do wiadomości, że Aplikacja oparta jest na
              technologii sztucznej inteligencji, która, ze względu na wczesną
              fazę rozwoju, może generować błędy lub niedokładności.
            </li>
            <li>
              Dostawca nie ponosi odpowiedzialności za decyzje podjęte przez
              Klienta na podstawie informacji uzyskanych za pośrednictwem
              Aplikacji.
            </li>
            <li>
              Klient zobowiązuje się do weryfikacji Treści Generowanych przez
              Aplikację przed ich wykorzystaniem w praktyce zawodowej.
            </li>
            <li>
              Dostawca nie gwarantuje, że korzystanie z Aplikacji przyniesie
              Klientowi określone rezultaty lub korzyści.
            </li>
          </ol>

          <h4 className={styles.titleH4}>§17 Przetwarzanie danych osobowych</h4>
          <ol>
            <li>Administratorem danych osobowych Klientów jest Dostawca.</li>
            <li>
              Dostawca przetwarza dane osobowe Klientów zgodnie z obowiązującymi
              przepisami prawa, w szczególności z Rozporządzeniem Parlamentu
              Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
              sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
              osobowych i w sprawie swobodnego przepływu takich danych oraz
              uchylenia dyrektywy 95/46/WE (RODO).
            </li>
            <li>
              Szczegółowe informacje dotyczące przetwarzania danych osobowych, w
              tym celów i podstaw przetwarzania oraz praw osób, których dane
              dotyczą, zawarte są w Polityce Prywatności dostępnej w Aplikacji.
            </li>
            <li>
              Klient ma prawo do dostępu do swoich danych osobowych, ich
              sprostowania, usunięcia lub ograniczenia przetwarzania, a także
              prawo do przenoszenia danych.
            </li>
            <li>
              W przypadku pytań lub wątpliwości dotyczących przetwarzania danych
              osobowych, Klient może skontaktować się z Dostawcą pod adresem
              e-mail: biuro@prawmi.pl.
            </li>
          </ol>
          <h4 className={styles.titleH4}>§18 Reklamacje</h4>
          <ol>
            <li>
              Klient ma prawo do składania reklamacji dotyczących działania
              Aplikacji lub świadczonych Usług.
            </li>
            <li>
              Reklamacje należy składać drogą elektroniczną na adres:
              reklamacje@prawmi.pl lub pisemnie na adres siedziby Dostawcy.
            </li>
            <li>
              Reklamacja powinna zawierać co najmniej: a) dane identyfikujące
              Klienta, b) opis problemu będącego podstawą reklamacji, c) żądanie
              Klienta.
            </li>
            <li>
              Dostawca rozpatrzy reklamację w terminie 14 dni od jej otrzymania
              i poinformuje Klienta o jej rozstrzygnięciu na adres e-mail
              przypisany do Konta Klienta.
            </li>
            <li>
              W przypadku konieczności uzupełnienia reklamacji, termin na
              rozpatrzenie reklamacji biegnie od dnia dostarczenia uzupełnionych
              informacji.
            </li>
            <li>
              Reklamacje niezawierające danych wymienionych w ust. 3 nie
              podlegają rozpatrzeniu.
            </li>
          </ol>
          <h4 className={styles.titleH4}>§19 Prawo właściwe i jurysdykcja</h4>
          <ol>
            <li>
              Prawem właściwym dla umowy zawartej pomiędzy Dostawcą a Klientem,
              której przedmiotem są Usługi, jest prawo polskie.
            </li>
            <li>
              Strony wyłączają zastosowanie norm prawa prywatnego
              międzynarodowego regulujących wybór prawa właściwego.
            </li>
            <li>
              Wszelkie spory wynikłe na tle realizacji umowy będą rozstrzygane
              przez sąd właściwy dla siedziby Dostawcy.
            </li>
          </ol>
          <h4 className={styles.titleH4}>§20 Postanowienia końcowe</h4>
          <ol>
            <li>Regulamin wchodzi w życie z dniem 16 października 2024 r.</li>
            <li>
              Dostawca zastrzega sobie prawo do zmiany Regulaminu. O wszelkich
              zmianach Klienci będą informowani poprzez wiadomość e-mail lub
              komunikat w Aplikacji co najmniej 14 dni przed wejściem zmian w
              życie.
            </li>
            <li>
              W przypadku, gdy Klient nie akceptuje nowej treści Regulaminu,
              zobowiązany jest zawiadomić o tym fakcie Dostawcę w ciągu 14 dni
              od daty poinformowania o zmianie Regulaminu. Brak akceptacji nowej
              wersji Regulaminu skutkuje rozwiązaniem umowy z końcem bieżącego
              Okresu rozliczeniowego.
            </li>
            <li>
              W sprawach nieuregulowanych w niniejszym Regulaminie zastosowanie
              mają przepisy prawa polskiego, w szczególności Kodeksu cywilnego
              oraz ustawy o świadczeniu usług drogą elektroniczną.
            </li>
            <li>
              Jeżeli którekolwiek z postanowień Regulaminu zostanie uznane za
              nieważne lub nieskuteczne przez właściwy sąd lub w wyniku zmiany
              legislacji, nie wpływa to na ważność czy skuteczność pozostałych
              postanowień Regulaminu.
            </li>
            <li>
              Regulamin jest stale dostępny do pozyskania pod adresem
              prawmi.pl/regulamin w formie umożliwiającej jego pobranie.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default StatutePage;
